import Form from "./components/Form";
import styles from "./styles.module.scss";

import FAQ from "../../../../components/FAQ";
import { accordions } from "./constants";

function Contact() {
  return (
    <section className={styles.background} id="contact">
      <div className={styles.container}>
        <FAQ items={accordions} />
        <Form />
      </div>
    </section>
  );
}

export default Contact;
