import React from "react";
import { useIntl } from "react-intl";
import Button from "../../../../components/Button";
import useIsMobile from "../../../../hooks/useIsMobile";
import Images from "../../assets";
import styles from "./styles.module.scss";

function Banner() {
  const intl = useIntl();
  const { isMobile } = useIsMobile();

  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          {intl.formatMessage({ id: "jobs_banner_title" })}
        </h1>
        <h2 className={styles.subtitle}>
          {intl.formatMessage({ id: "jobs_banner_description" })}{" "}
          <span className={styles.hashtag}>
            {intl.formatMessage({
              id: "jobs_banner_hashtag",
            })}
          </span>
        </h2>
        <a href="#jobs">
          <Button>
            {intl.formatMessage({
              id: "jobs_banner_button",
            })}
          </Button>
        </a>
      </div>
      <div className={styles.image}>
        <img src={isMobile ? Images.BannerMobile : Images.Banner} alt="" />
      </div>
    </section>
  );
}

export default Banner;
