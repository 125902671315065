import React, { useContext } from "react";
import { IntlProvider } from "react-intl";
import "./App.css";
import { DataContext } from "./context/DataContext";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import AppRoutes from "./routes/index";

function App() {
  const { context } = useContext(DataContext);

  return (
    <IntlProvider
      messages={messages[context]}
      locale={context}
      defaultLocale={LOCALES.PORTUGUESE}
    >
      <AppRoutes />
    </IntlProvider>
  );
}

export default App;
