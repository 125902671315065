import React from "react";
import styles from "./styles.module.scss";

import { useIntl } from "react-intl";
import WhiteIcons from "../../../../assets/icons/white";

function Solutions() {
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        {intl.formatMessage({
          id: "outsourcing_solutions_title",
        })}
      </h3>
      <p className={styles.description}>
        {intl.formatMessage({
          id: "outsourcing_solutions_description",
        })}
      </p>
      <div className={styles.content}>
        <div className={styles.card}>
          <WhiteIcons.Internet />
          <h4 className={styles.cardTitle}>
            {intl.formatMessage({
              id: "outsourcing_solutions_outsourcing_title",
            })}
          </h4>
          <p className={styles.cardDescription}>
            {intl.formatMessage({
              id: "outsourcing_solutions_outsourcing_description",
            })}
          </p>
        </div>
        <div className={styles.card}>
          <WhiteIcons.Users />
          <h4 className={styles.cardTitle}>
            {intl.formatMessage({
              id: "outsourcing_solutions_agilesquads_title",
            })}
          </h4>
          <p className={styles.cardDescription}>
            {intl.formatMessage({
              id: "outsourcing_solutions_agilesquads_description",
            })}
          </p>
        </div>
      </div>
    </section>
  );
}

export default Solutions;
