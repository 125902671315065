import React, { useContext } from "react";
import TextPage from "../../components/TextPage";
import { DataContext } from "../../context/DataContext";
import EN from "./Texts/en";
import ES from "./Texts/es";
import PT from "./Texts/pt";

function Security() {
  const { context } = useContext(DataContext);

  const title = {
    pt: "Plano de resposta a incidentes",
    es: "Plan de respuesta a incidentes",
    en: "Incident response plan",
  };

  const text = {
    pt: <PT />,
    es: <ES />,
    en: <EN />,
  };

  return <TextPage title={title[context]} text={text[context]} />;
}

export default Security;
