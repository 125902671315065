import { Dialog } from "@headlessui/react";
import axios from "axios";
import { useState, useRef } from "react";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import File from "./File";
import styles from "./styles.module.scss";
import ReCAPTCHA from "react-google-recaptcha";

function ApplicantInfo({ jobTitle, setStep }) {
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null);
  const [captchaWarning, setCaptchaWarning] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const captchaValue = captchaRef.current.getValue();
    if (!captchaValue) {
      setCaptchaWarning(true);
    } else {
      setLoading(true);
      try {
        const { name, email, telephone, linkedin, message } = e.target;

        await axios.post(
          "https://admin.carreiras.startaideia.dev/api/send-email",
          {
            job_title: jobTitle,
            candidate_name: name.value,
            candidate_email: email.value,
            candidate_phone: telephone.value,
            candidate_linkedin: linkedin.value,
            candidate_message: message.value,
            attachment: file,
          }
        );
        setStep(3);
      } catch (error) {
        console.log(error);
      } finally {
        document.getElementById("job-form").reset();
        window.grecaptcha.reset();
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className={styles.header}>
        <button onClick={() => setStep(1)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="arrow-right-line">
              <path
                id="Path 27"
                d="M7.828 13.0001L13.192 18.3641L11.778 19.7781L4 12.0001L11.778 4.22212L13.192 5.63612L7.828 11.0001L20 11.0001L20 13.0001L7.828 13.0001Z"
                fill="black"
              />
            </g>
          </svg>
        </button>
        <Dialog.Title className={styles.title} as="h4">
          Inserir Dados
        </Dialog.Title>
      </div>
      <hr />
      <form id="job-form" onSubmit={handleSubmit}>
        <fieldset className={styles.content}>
          <legend className={styles.legend}>
            Por favor, forneça suas informações para iniciar o processo seletivo
          </legend>
          <div className={styles.fields}>
            <input
              name="name"
              type="text"
              placeholder="Informe seu nome completo"
              required
            />
            <input
              name="email"
              type="email"
              placeholder="Seu e-mail"
              required
            />
            <input
              name="telephone"
              type="text"
              placeholder="Número de telefone"
              required
            />
            <input
              name="linkedin"
              type="text"
              placeholder="Perfil do LinkedIn"
            />
            <File file={file} setFile={setFile} />
            <textarea
              name="message"
              rows={4}
              placeholder="Deixe sua mensagem aqui. Estamos ansiosos para conhecê-lo..."
            />
          </div>
          <ReCAPTCHA
            ref={captchaRef}
            sitekey="6LdKkQMqAAAAAKTql9WdR4ACMxcyphI9kJqOw47S"
            onChange={() => setCaptchaWarning(false)}
          />
          {captchaWarning && (
            <i className={styles.error}>
              Atenção! É obrigatório o preenchimento do Captcha!
            </i>
          )}
          <div className={styles.button}>
            <Button loading={loading} disabled={loading} type="submit">
              Enviar candidatura
            </Button>
          </div>
        </fieldset>
      </form>
    </>
  );
}

export default ApplicantInfo;
