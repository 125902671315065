import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Icons from "../../../../assets/icons";
import { items } from "./constants";
import styles from "./styles.module.scss";

function Hub() {
  const [active, setActive] = useState(1);
  const carouselRef = useRef(null);

  const handleNext = () => {
    carouselRef.current.scrollLeft += carouselRef.current.offsetWidth + 4;
    setActive(active + 1);
  };

  const handlePrev = () => {
    carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth + 4;
    setActive(active - 1);
  };

  useEffect(() => {
    const handleResize = () => {
      carouselRef.current.scrollLeft = 0;
      setActive(1);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        <FormattedMessage id="about_hub_title" defaultMessage="Onde estamos" />
      </h3>
      <div className={styles.arrows}>
        <button
          className={styles.arrow}
          onClick={handlePrev}
          aria-label="Previous"
          disabled={active === 1}
        >
          <Icons.ArrowRightCircle />
        </button>
        <button
          className={styles.arrow}
          onClick={handleNext}
          aria-label="Next"
          disabled={active === items.length}
        >
          <Icons.ArrowRightCircle />
        </button>
      </div>
      <div className={styles.carousel} ref={carouselRef}>
        {items.map((item) => (
          <div key={item.title} className={styles.item}>
            <h4 className={styles.place}>{item.title}</h4>
            <div className={styles.images}>
              {item.images.map((image, index) => (
                <div className={styles.image} key={index}>
                  <img src={image.src} alt={image.alt} loading="lazy" />
                </div>
              ))}
            </div>
            <p className={styles.address}>{item.address}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Hub;
