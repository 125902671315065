import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import useIsMobile from "../../hooks/useIsMobile";
import About from "./components/About";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Demonstration from "./components/Demonstration";
import ForYourBusiness from "./components/ForYourBusiness";
import OurSolutions from "./components/OurSolutions";
import Services from "./components/Services";
import styles from "./styles.module.scss";

function HealthProject() {
  const { isMobile } = useIsMobile();

  return (
    <div className={styles.container}>
      <Navbar />
      <main>
        <Banner isMobile={isMobile} />
        <About />
        <OurSolutions />
        {!isMobile && <Demonstration />}
        <Services />
        <ForYourBusiness />
        <Contact isMobile={isMobile} />
      </main>
      <Footer />
    </div>
  );
}

export default HealthProject;
