import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PinkIcons from "../../../assets/icons/pink";
import Button from "../../../components/Button";
import Footer from "../../../components/Footer";
import Loader from "../../../components/Loader";
import Navbar from "../../../components/Navbar";
import Modal from "./Modal";
import styles from "./styles.module.scss";

function Job() {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const { slug } = useParams();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://admin.carreiras.startaideia.dev/api/jobs-slug/${slug}`
      );
      setJob(response.data.job[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading || !job)
    return (
      <div className={styles.loader}>
        <Loader variant="secondary" />
      </div>
    );

  return (
    <>
      <Navbar />
      <main>
        <div className={styles.header}>
          <div className={styles.container}>
            <h1 className={styles.title}>{job.title}</h1>
            <div className={styles.wrapper}>
              <p>
                {<PinkIcons.Location />}
                {job.modality}
              </p>
              <p>
                {<PinkIcons.Clock />}
                {job.workload}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <section className={styles.section}>
            <h2 className={styles.subtitle}>Sobre a vaga</h2>
            <div
              dangerouslySetInnerHTML={{ __html: job.job_description }}
              className={styles.items}
            />
          </section>
          <section className={styles.section}>
            <h2 className={styles.subtitle}>Responsabilidades</h2>
            <div
              dangerouslySetInnerHTML={{ __html: job.job_assignment }}
              className={styles.items}
            />
          </section>
          <section className={styles.section}>
            <h2 className={styles.subtitle}>Requisitos</h2>
            <div
              dangerouslySetInnerHTML={{ __html: job.job_requirement }}
              className={styles.items}
            />
          </section>
          {job.job_differentials && (
            <section className={styles.section}>
              <h2 className={styles.subtitle}>Diferenciais</h2>
              <div
                dangerouslySetInnerHTML={{ __html: job.job_differentials }}
                className={styles.items}
              />
            </section>
          )}
          <div className={styles.button}>
            <Button onClick={() => setModalOpen(true)}>Aplique agora</Button>
          </div>
        </div>
      </main>
      <Footer />

      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        jobTitle={job.title}
      />
    </>
  );
}

export default Job;
