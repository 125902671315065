import React from "react";
import { useIntl } from "react-intl";
import { items } from "./contants";
import styles from "./styles.module.scss";

function BusinessBenefits() {
  const intl = useIntl();

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: "outsourcing_businessbenefits_title",
          })}
        </h3>
        <div className={styles.content}>
          {items.map((item, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.icon}> {item.icon} </div>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default BusinessBenefits;
