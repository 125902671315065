import React, { useState } from "react";
import { CgClose, CgMenu } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import Logos from "../../assets/logos";
import routesPaths from "../../routes/routesPaths";
import Dropdown from "./Dropdown";
import Language from "./Language";
import SocialMedia from "./SocialMedia";
import { menu } from "./constants";
import styles from "./styles.module.scss";

function Navbar() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const navigate = useNavigate();
  return (
    <header className={styles.background}>
      <div className={styles.container}>
        <Logos.Startaideia
          className={styles.logo}
          onClick={() => navigate(routesPaths.home)}
        />
        <nav className={`${styles.items} ${styles.desktop}`}>
          <ul className={`${styles.items}`}>
            {menu.map((item) => {
              return item.submenu ? (
                <li key={item.link}>
                  <Dropdown title={item.label} items={item.submenu} />
                </li>
              ) : (
                <li key={item.link}>
                  <Link className={styles.link} to={item.link}>
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className={styles.wrapper}>
          <SocialMedia />
          <Language />
          <button
            onClick={() => setOpenMobileMenu((prev) => !prev)}
            className={styles.mobile}
            aria-label={`${openMobileMenu ? "Fechar" : "Abrir"} Menu`}
          >
            {openMobileMenu ? <CgClose size={24} /> : <CgMenu size={24} />}
          </button>
        </div>
      </div>
      {openMobileMenu && (
        <nav
          className={`${openMobileMenu && styles.openMobileMenu} ${
            styles.mobile
          }`}
          role="navigation"
        >
          <ul>
            {menu.map((item) => {
              return item.submenu ? (
                item.submenu.map((subItem) => (
                  <li key={subItem.label}>
                    <Link
                      className={styles.link}
                      to={subItem.link}
                      role="menuitem"
                    >
                      {subItem.label}
                    </Link>
                  </li>
                ))
              ) : (
                <li key={item.label}>
                  <Link className={styles.link} to={item.link} role="menuitem">
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
          <SocialMedia type="mobile" />
        </nav>
      )}
    </header>
  );
}

export default Navbar;
