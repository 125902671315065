import { useIntl } from "react-intl";
import PinkIcons from "../../../../assets/icons/pink";
import styles from "./styles.module.scss";

function HowWeExecute() {
  const intl = useIntl();
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: "outsourcing_howweexecute_title",
          })}
        </h3>
        <div className={styles.items}>
          <div className={styles.item}>
            <PinkIcons.Magnet />
            <h4 className={styles.subtitle}>
              {intl.formatMessage({
                id: "outsourcing_howweexecute_talentattraction_title",
              })}
            </h4>
            <ul className={styles.description}>
              <li>
                {intl.formatMessage({
                  id: "outsourcing_howweexecute_talentattraction_description1",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "outsourcing_howweexecute_talentattraction_description2",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "outsourcing_howweexecute_talentattraction_description3",
                })}
              </li>
            </ul>
          </div>
          <div className={styles.item}>
            <PinkIcons.Hand />
            <h4 className={styles.subtitle}>
              {intl.formatMessage({
                id: "outsourcing_howweexecute_retaining_talent_title",
              })}
            </h4>
            <ul className={styles.description}>
              <li>
                {intl.formatMessage({
                  id: "outsourcing_howweexecute_retaining_talent_description1",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "outsourcing_howweexecute_retaining_talent_description2",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "outsourcing_howweexecute_retaining_talent_description3",
                })}
              </li>
            </ul>
          </div>
          <div className={styles.item}>
            <PinkIcons.Graphic />
            <h4 className={styles.subtitle}>
              {intl.formatMessage({
                id: "outsourcing_howweexecute_optimized_management_title",
              })}
            </h4>
            <p className={styles.description}>
              {intl.formatMessage({
                id: "outsourcing_howweexecute_optimized_management_description",
              })}
            </p>
          </div>
          <div className={styles.path}>
            <div className={styles.circle} />
            <hr />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowWeExecute;
