import { useIntl } from "react-intl";
import { cards } from "./constants";
import styles from "./styles.module.scss";

function OurServices() {
  const intl = useIntl();

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h3>
            {intl.formatMessage({ id: "softwarefactory_ourservices_title" })}
          </h3>
          <p>
            {intl.formatMessage({
              id: "softwarefactory_ourservices_description",
            })}
          </p>
        </div>
        <div className={styles.cards}>
          {cards.map((card, i) => (
            <div key={i} className={styles.card}>
              {card.icon}
              <p className={styles.title}>{card.title}</p>
              <p className={styles.description}>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default OurServices;
