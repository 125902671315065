import { formatMessage } from "../../../../utils/formatMessage";

export const accordions = [
  {
    title: formatMessage("softwarefactory_contact_accordion1_title"),
    content: formatMessage("softwarefactory_contact_accordion1_content"),
  },
  {
    title: formatMessage("softwarefactory_contact_accordion2_title"),
    content: formatMessage("softwarefactory_contact_accordion2_content"),
  },
  {
    title: formatMessage("softwarefactory_contact_accordion3_title"),
    content: formatMessage("softwarefactory_contact_accordion3_content"),
  },
  {
    title: formatMessage("softwarefactory_contact_accordion4_title"),
    content: formatMessage("softwarefactory_contact_accordion4_content"),
  },
];
