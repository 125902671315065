function ES() {
  return (
    <div>
      <h2>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h2>
      <h3>Bienvenido (a)!</h3>
      <section>
        <p>
          A continuación, compartimos informaciones importantes para que pueda
          comprender cómo se procesan sus datos personales. ¡Buena lectura!
        </p>
        <p>
          STARTAIDEIA es una empresa que trabaja para generar negocios en el
          área de la tecnología a través de soluciones creativas e inteligentes,
          con el objetivo de ayudar a emprendedores a materializar ideas y
          transformarlas en grandes proyectos.
        </p>
        <p>
          El objetivo de esta Política de Privacidad y Protección de Datos es
          regular el procesamiento de datos personales de nuestros Clientes,
          Proveedores de Servicios, Socios Comerciales y Empleados.
        </p>
        <p>
          Al definir las prácticas que rigen nuestras actividades, asumimos el
          compromiso y la responsabilidad de respetar y observar la Ley General
          de Protección de Datos (Ley no 13.709/18) y demás leyes aplicables en
          la materia, y nos comprometemos a adoptar las mejores prácticas para
          asegurar que sus datos personales son tratados de forma adecuada y
          segura.
        </p>
        <p>
          Las disposiciones de esta Política de Privacidad se aplican
          <b> STARTAIDEIA TECNOLOGIA DA INFORMAÇÃO LTDA. (STARTAIDEIA)</b>,
          persona jurídica de derecho privado, registrada en el CNPJ con el no
          16.713.376/0001-83, con sede en Rua Candido Silveira, no 198, Sala
          304, en el municipio de Porto Alegre - RS, CEP 95.540-010 .
        </p>
        <p>
          Para una mejor comprensión de las operaciones de procesamiento de
          datos realizadas por <b>STARTAIDEIA</b>, le recomendamos que usted
          “Usuario” lea atentamente los términos de esta “Política”, incluso con
          el propósito de validar su consentimiento en relación con los términos
          de privacidad y protección de datos personales. datos que usted recibe
          se refiere, de conformidad con el artículo 7, inciso I y el artículo
          33, incisos VIII y IX, de la Ley N° 13.709/2018 (Ley General de
          Protección de Datos Personales - LGPD).En caso de desacuerdo con
          nuestra Política de Privacidad y Términos de Uso, el usuario deberá
          abstenerse de utilizar nuestros servicios.
        </p>
      </section>
      <section>
        <h4>1. PROCESAMIENTO DE DATOS PERSONALES</h4>
        <p>
          Se consideran datos personales toda información relativa a las
          personas, que pueden ser identificadas, directa o indirectamente, a
          través de información como: nombre, datos de ubicación, identificación
          por medios electrónicos (correo electrónico, IP, teléfonos
          inteligentes) u otras características específicas que Permitir la
          identificación individual del interesado.
        </p>
        <p>
          Cualquier información de identificación personal que nos proporcione
          se utilizará únicamente según lo permita la ley, incluso según sea
          necesario para proporcionar el producto, servicio o información que
          solicitó.
        </p>
        <p>
          Your personal data will not be sold or rented, and sharing, if any, is
          limited to our business partners as required in the execution of our
          services or as required by laws and regulations, in which case
          STARTAIDEIA takes all necessary measures to ensure that subcontractors
          meet applicable legal requirements and provide adequate guarantees for
          data protection.
        </p>
      </section>
      <section>
        <h4>2. QUÉ DATOS RECOGEMOS:</h4>
        <p>
          Si el “Usuario” está interesado en contactar con nosotros a través de
          este sitio web, se le solicitarán los siguientes datos personales:
          nombre, teléfono, correo electrónico.
        </p>
        <p>
          Para llevar a cabo nuestras actividades y ejecutar contratos para el
          desarrollo de programas informáticos personalizables y no
          personalizables, soporte técnico y otros servicios informáticos, se
          recabarán, entre otra información, los siguientes datos personales:
          nombre del contratista y/o persona jurídica representante, CPF,
          teléfono, correo electrónico, registro profesional.
        </p>
        <p>
          Para el reclutamiento y selección de personal se recogerá inicialmente
          el nombre, domicilio, dirección de correo electrónico y demás datos
          proporcionados por el candidato a la vacante en su currículum vitae.
        </p>
        <p>
          En los casos en que el titular de datos personales participe en
          eventos o webinars realizados por Startaideia, registrándose a través
          de plataformas de servicios en línea, es importante señalar que,
          además de los Términos de esta Política, también se aplicarán las
          Políticas de Privacidad y los Términos de Uso del Agente/Promotor del
          Evento (Operador).
        </p>
        <p>
          Por lo tanto, es responsabilidad del usuario leer los términos de la
          Política de Privacidad del Agente/Promotor de Eventos, para manifestar
          su consentimiento al tratamiento de sus datos personales. De ser
          necesario, STARTAIDEIA podrá solicitar otros datos personales, con el
          fin de confirmar su identidad y correspondencia con los datos
          ingresados al contratar los servicios, y esta solicitud podrá
          realizarse vía telefónica, correo electrónico, WhatsApp.
        </p>
      </section>
      <section>
        <h4>3. BASES PARA EL TRATAMIENTO DE LOS DATOS:</h4>
        <p>
          En el desarrollo de las actividades de STARTAIDEIA, el tratamiento de
          datos personales forma parte de la ejecución y cumplimiento de un
          contrato; en cumplimiento de obligaciones legales y reglamentarias, en
          el interés legítimo de la empresa, y en los casos en que así se
          requiera, mediante la prestación del consentimiento del titular,
          garantizándose a los titulares de los datos personales los derechos
          previstos en el artículo 18 de la Ley General de Protección de Datos
          Personales. (Ley no 13.709/18).
        </p>
        <p>
          Los datos personales de los usuarios podrán ser utilizados con fines
          legales por el responsable del tratamiento cuando sea demandado
          judicialmente o cuando sea necesario para interponer posibles acciones
          legales derivadas del uso inadecuado de este servicio o de servicios
          relacionados con el mismo.
        </p>
        <p>
          STARTAIDEIA podrá revelar datos personales siempre que lo soliciten
          las Autoridades Públicas o Autoridad Judicial para cumplir con una
          obligación legal.
        </p>
        <p>
          El responsable del tratamiento también podrá revelar datos personales
          previa solicitud de las autoridades públicas o para cumplir con una
          obligación legal.
        </p>
      </section>
      <section>
        <h4>4. CON QUIÉN COMPARTIMOS TUS DATOS:</h4>
        <p>
          En el ámbito de nuestras actividades empresariales y relaciones
          comerciales, la información relacionada con la razón social, sector,
          cargo, departamento, función y datos personales de los representantes
          legales y sus empleados podrá ser compartida con socios comerciales y
          empleados, para la ejecución de un contrato o cumplimiento de una
          obligación legal.
        </p>
        <p>
          Los Prestadores de Servicios de STARTAIDEIA también tendrán acceso y
          podrán compartir la información y datos de los titulares tratados,
          orientándose para que la transmisión de datos personales se realice
          exclusivamente para las finalidades necesarias y en cumplimiento de la
          Ley General de Protección de Datos y demás normas. y determinaciones
          de los organismos reguladores.
        </p>
        <p>
          En caso de venta, fusión o transferencia de todo o parte de nuestro
          negocio (incluso en caso de reorganización, disolución, liquidación u
          otra reestructuración corporativa), podemos revelar sus datos
          personales al vendedor o comprador potencial o a sus representantes.
          siempre que se adopten las medidas adecuadas para garantizar la
          seguridad y confidencialidad de la información. Sus datos NO serán
          utilizados para otros fines, como compartir información y listas de
          correo electrónico con fines de marketing.
        </p>
      </section>
      <section>
        <h4>5. TRANSFERENCIA INTERNACIONAL DE DATOS</h4>
        <p>
          Para la realización y desarrollo de proyectos y soluciones innovadoras
          en el área de tecnología, STARTAIDEIA podrá realizar la transferencia
          internacional de datos personales, de conformidad con el artículo 33,
          inciso IX, de la Ley 13.709/2018, es decir, cuando sea necesario para
          la ejecución de un contrato o trámites previos relacionados con un
          contrato en el que el interesado es parte, a petición del interesado;
          para el cumplimiento de una obligación legal o reglamentaria por parte
          del responsable del tratamiento o para el ejercicio regular de
          derechos en procedimientos judiciales, administrativos o arbitrales.
        </p>
        <p>
          Al compartir datos personales con países extranjeros, adoptamos las
          mejores prácticas de seguridad de la información, buscando brindar a
          nuestros clientes y titulares de datos personales el grado de secreto
          y protección adecuado al previsto en la LGPD, y, en estos casos, se
          requiere el consentimiento. solicitado al interesado.
        </p>
      </section>
      <section>
        <h4>6. ¿CÓMO SE RECOGEN TUS DATOS?</h4>
        <p>
          Los datos personales podrán ser recabados a través de los siguientes
          medios:
        </p>
        <ul>
          <li> Datos de registro;</li>
          <li> Sitio web;</li>
          <li> Correo electrónico;</li>
          <li> Aplicaciones de mensajería y conferencias;</li>
          <li> Selección y reclutamiento de empleados;</li>
          <li> Seminarios web y eventos en línea;</li>
          <li>
            A través de acciones realizadas por el titular en los perfiles de la
            empresa en redes sociales, tales como likes, comentarios o
            similares;
          </li>
          <li>
            Cuando se ponga a disposición del público, incluidas, entre otras,
            las redes sociales (como Facebook, Instagram y LinkedIn).
          </li>
        </ul>
        <p>
          Algunos datos personales recogidos son obligatorios y necesarios para
          la ejecución y desarrollo de nuestras actividades, y en caso de falta
          o insuficiencia de estos datos, STARTAIDEIA se reserva el derecho de
          no prestar los servicios o la información solicitada. En cada caso,
          informaremos a los interesados sobre la obligatoriedad del suministro
          de estos datos personales y la finalidad de su uso.
        </p>
        <p>
          Aclaramos que las interacciones de los usuarios con nuestros perfiles
          en redes sociales se rigen por las políticas de privacidad y términos
          de uso de las respectivas plataformas.
        </p>
      </section>
      <section>
        <h4>7. DURACIÓN DEL TRATAMIENTO</h4>
        <p>
          La duración del tratamiento de los datos se producirá por tiempo
          indefinido, garantizándose el fin del tratamiento en los siguientes
          casos:
        </p>
        <ul>
          <li>
            Verificación de que se logró el propósito o que los datos ya no son
            necesarios o relevantes para lograr el propósito específico para el
            cual fueron recopilados;
          </li>
          <li>
            Comunicación del titular, incluso cuando ejerza su derecho a revocar
            el consentimiento, salvaguardando el interés público;
          </li>
          <li>
            Determinación de la Autoridad Nacional, cuando exista violación de
            disposiciones legales.
          </li>
        </ul>
      </section>
      <section>
        <h4>8. ALMACENAMIENTO</h4>
        <p>
          Los datos personales se almacenan en entornos seguros dentro de
          nuestra infraestructura de TI y proveedores de servicios de
          procesamiento de datos en la nube que han sido previamente evaluados
          en cuanto a la seguridad de los datos.
        </p>
        <p>
          Además de estas medidas técnicas y de seguridad de la información,
          STARTAIDEIA adopta medidas administrativas para la gestión de riesgos
          con Empleados y Subcontratistas, que incluyen la formalización de
          Contratos, Reglamento de Protección de Datos, Términos de
          Responsabilidad, Códigos de Conducta, entre otras, con el objetivo de
          orientar a los empleados. sobre su conducta en materia de protección y
          privacidad de datos personales.
        </p>
        <p>
          De conformidad con el punto 8, si no es necesario conservarlos, los
          datos personales se eliminarán una vez finalizado su tratamiento. No
          obstante, se conservarán los datos personales que estén destinados al
          cumplimiento de una obligación legal o reglamentaria por parte de
          STARTAIDEIA, o que estén destinados a su uso exclusivo, garantizando
          la anonimización dentro de los límites técnicos posibles.
        </p>
      </section>
      <section>
        <h4>9. DERECHOS DEL TITULAR DE LOS DATOS PERSONALES:</h4>
        <p>
          STARTAIDEIA reconoce que toda persona tiene derecho a la protección de
          sus datos personales y garantiza que los interesados ejerzan los
          derechos previstos en la Ley N° 13.709/2018, incluido el derecho a
          obtener, previa solicitud al Responsable:
        </p>
        <ul>
          <li>Confirmación de la existencia del tratamiento;</li>
          <li>Acceso a datos;</li>
          <li>Corrección de datos incompletos, inexactos o desactualizados;</li>
          <li>
            Anonimización, bloqueo o eliminación de datos innecesarios,
            excesivos o tratados en incumplimiento de la legislación vigente;
          </li>
          <li>
            Portabilidad de datos a otro proveedor de servicios o productos;
          </li>
          <li>
            Eliminación de los datos personales tratados con el consentimiento
            del titular, salvo en los casos previstos en el art. 16 Ley no 13.
            709/2018;
          </li>
          <li>
            Información de entidades públicas y privadas con las que el
            Responsable compartió datos;
          </li>
          <li>
            Información sobre la posibilidad de no dar el consentimiento y las
            consecuencias de negarlo;
          </li>
          <li>
            Revocación del consentimiento, de conformidad con el §5 del art. 8
            de la Ley N° 13.709/2018.
          </li>
        </ul>
        <p>
          El ejercicio de los derechos antes descritos, quejas u otras
          comunicaciones, deberá realizarse previa solicitud expresa del titular
          o representante legalmente constituido, y remitida a STARTAIDEIA a
          través de los canales de contacto previstos al final de esta Política.
        </p>
      </section>
      <section>
        <h4>10. RESPONSABILIDADES</h4>
        <p>
          STARTAIDEIA no se hará responsable de los daños y perjuicios que se
          deriven del tratamiento inadecuado de los datos hechos públicos por
          parte del titular, ni se hará responsable, entre otras hipótesis:
        </p>
        <ul>
          <li>
            Si el daño es resultado de culpa exclusiva del titular o de
            terceros; o
          </li>
          <li>Si el daño surge de caso fortuito o fuerza mayor.</li>
        </ul>
      </section>
      <section>
        <h4>11. CONFIDENCIALIDAD DE LA INFORMACIÓN:</h4>
        <p>
          Para garantizar la seguridad del Usuario, toda la información
          compartida con STARTAIDEIA se mantiene confidencial y confidencial, de
          acuerdo con lo dispuesto en la Ley General de Protección de Datos (Ley
          13.709/2018).
        </p>
        <p>
          Para el desarrollo de Proyectos y Soluciones innovadoras en el área de
          Tecnología, STARTAIDEIA garantiza el secreto, el deber de
          confidencialidad y la no divulgación de información confidencial.
        </p>
      </section>
      <section>
        <h4>12. MEDIDAS DE SEGURIDAD:</h4>
        <p>
          Con el fin de garantizar la confidencialidad, integridad y
          disponibilidad de los datos y sistemas de información utilizados,
          STARTAIDEIA adopta medidas técnicas, organizativas y administrativas
          para proteger los datos personales de accesos no autorizados y
          situaciones accidentales o ilícitas de destrucción, pérdida,
          alteración, comunicación o difusión, riesgos y ciberataques, de
          conformidad con lo dispuesto en la Ley General de Protección de Datos
          Personales (Ley N° 13.709/18).
        </p>
        <p>
          De este modo, STARTAIDEIA adopta los mejores esfuerzos para preservar
          la privacidad de los datos de los usuarios, de acuerdo con la
          tecnología actualmente disponible. Sin embargo, no se puede garantizar
          que los recursos utilizados sean completamente seguros, especialmente
          aquellos a través de los cuales es posible la violación y el acceso no
          autorizado, realizado mediante métodos de ingeniería social y
          dispositivos desarrollados para obtener información de manera
          indebida.
        </p>
        <p>
          Por ello, STARTAIDEIA aconseja a sus Usuarios que adopten también las
          medidas adecuadas para protegerse de violaciones indebidas y no
          autorizadas, como instalar software de seguridad, conservar los datos
          de acceso y las contraseñas.
        </p>
        <p>
          STARTAIDEIA se compromete a comunicar a la Autoridad Nacional y al
          Titular sobre la ocurrencia de incidentes de seguridad que puedan
          causar riesgo o daño significativo a los titulares, de acuerdo con los
          plazos y regulaciones legales.
        </p>
      </section>
      <section>
        <h4>13. INCIDENTE DE SEGURIDAD:</h4>
        <p>
          En caso de un riesgo cibernético o incidente de seguridad, que resulte
          en un posible daño o pérdida en el procesamiento y almacenamiento de
          datos e información de Empleados, Clientes, Socios Comerciales y
          Proveedores de Servicios, STARTAIDEIA notificará a la Autoridad
          Nacional de Protección de Datos dentro de los 2 (dos) días hábiles,
          contados a partir de la fecha del conocimiento del hecho, sobre:
        </p>
        <ul>
          <li>La naturaleza de los datos personales afectados;</li>
          <li>Información sobre los titulares involucrados;</li>
          <li>
            Qué medidas técnicas y de seguridad se utilizarán para proteger los
            datos;
          </li>
          <li>El perfil de riesgos relacionados con el incidente;</li>
          <li>
            Las medidas a adoptar para revertir o mitigar los efectos del
            incidente de seguridad.
          </li>
          <li>
            El Usuario también será notificado, en forma de alerta de
            incidencia, sobre los hechos ocurridos.
          </li>
        </ul>
      </section>
      <section>
        <h4>14. CÓMO UTILIZAMOS LAS COOKIES:</h4>
        <p>
          Las cookies son pequeños fragmentos de datos enviados por un servidor
          web a su navegador y almacenados en su computadora o dispositivo
          personal. Para ofrecer una mejor experiencia al utilizar nuestros
          servicios y aplicaciones web, podemos utilizar cookies de sesión (que
          caducan cuando cierra su navegador) y cookies persistentes (que
          permanecen en su computadora hasta que las elimina). Este tipo de
          información se recopila para hacer que el servicio sea más útil y
          brindarle una experiencia más conveniente y valiosa.
        </p>
        <p>
          Utilizamos dos categorías de cookies: cookies que proporcionamos
          directamente a su computadora o dispositivo para que podamos
          reconocerlo cuando regrese a nuestro sitio web y cookies de terceros
          utilizadas para el acceso al sitio web, estadísticas de seguridad y
          disponibilidad. Puedes gestionar las cookies directamente en tu
          navegador.
        </p>
      </section>
      <section>
        <h4>15. LEGISLACIÓN APLICABLE</h4>
        Esta Política se regirá, interpretará y ejecutará de acuerdo con las
        Leyes vigentes en la República Federativa de Brasil, en particular, las
        disposiciones de la Ley N° 13.709/2018 (LGPD) y GDPR - Reglamento
        General de Protección de Datos.
      </section>
      <section>
        <h4>16. ENTITY RESPONSIBLE FOR DATA PROCESSING:</h4>
        <p>
          La entidad responsable del tratamiento de los datos es
          <b> STARTAIDEIA TECNOLOGIA DA INFORMAÇÃO LTDA. (STARTAIDEIA)</b>,
          persona jurídica de derecho privado, inscrita en el CNPJ con el número
          16.713.376/0001-83.
        </p>
      </section>
      <section>
        <h4>17. DELGADO DE PROTECCIÓN DE DATOS:</h4>
        <p>Nombre: Diógenes Dorneles da Rosa</p>
        <p>Correo electrónico: jony@startaideia.com.br</p>
      </section>
      <section>
        <p>Fecha de la última actualización: octubre del 2023</p>
      </section>
      <section>
        <h4>GLOSARIO</h4>
        <p>
          • Leyes de Protección de Datos: son todas las Leyes Aplicables
          relacionadas con el Tratamiento de Datos Personales.
        </p>
        <p>
          • Datos Personales: toda información relativa a las personas, que
          pueden ser identificadas directa o indirectamente, a través de
          informaciones tales como: nombre, CPF, DNI, número de identificación
          del trabajador, identificación por medios electrónicos (correo
          electrónico, IP, teléfonos inteligentes), membresía.
        </p>
        <p>
          • Tratamiento de Datos: cualquier operación realizada con datos
          personales, tales como las relativas a recolección, producción,
          recepción, clasificación, uso, acceso, reproducción, transmisión,
          distribución, procesamiento, archivo, almacenamiento, eliminación,
          evaluación o control de la información, modificación. , comunicación,
          transferencia, difusión o extracción;
        </p>
        <p>
          • Transferencia Internacional de Datos: es el procedimiento mediante
          el cual el Agente/Participante de operaciones de exportación e
          importación de bienes procesa, almacena y transfiere datos e
          información personal entre diferentes países.
        </p>
        <p>
          • Titular: persona natural a quien se refieren los datos personales
          objeto de tratamiento;
        </p>
        <p>
          • Responsable del tratamiento: persona física o jurídica, de derecho
          público o privado, responsable de las decisiones relativas al
          tratamiento de datos personales;
        </p>
        <p>
          • Operador: persona física o jurídica, de derecho público o privado,
          que procesa datos personales por cuenta del responsable;
        </p>
        <p>
          • Responsable: persona designada por el responsable y operador para
          actuar como canal de comunicación con los titulares de los datos y la
          Autoridad Nacional de Protección de Datos (ANPD);
        </p>
        <p>
          • Consentimiento: expresión libre, informada e inequívoca por la cual
          el titular acepta el tratamiento de sus datos personales para una
          finalidad específica;
        </p>
        <p>
          • Eliminación: eliminación de un dato o conjunto de datos almacenados
          en una base de datos, cualquiera que sea el procedimiento utilizado;
        </p>
        <p>
          • GDPR: Reglamento General de Protección de Datos, es el conjunto de
          normas y regulaciones para las prácticas de uso de datos en el entorno
          digital creado por el Parlamento Europeo y el Consejo de la Unión
          Europea.
        </p>
        <p>
          • Autoridad Nacional de Protección de Datos (ANPD): es el organismo de
          la administración pública responsable de velar, implementar y
          monitorear el cumplimiento de la Ley General de Protección de Datos.
          Prestadores de Servicios: Son personas físicas o jurídicas, sin
          relación laboral, contratadas por Startaideia para desarrollar una
          actividad o realizar proyectos, a cambio de una remuneración.
        </p>
        <p>
          • Socios Comerciales: Son todas las partes interesadas que tienen
          algún tipo de interés, influencia o impacto en las actividades y
          resultados de nuestra organización. Esto incluye clientes, socios,
          empleados, proveedores u otros grupos que puedan afectar o verse
          afectados por las operaciones de la empresa.
        </p>
      </section>
    </div>
  );
}

export default ES;
