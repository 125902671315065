import React from "react";
import { useIntl } from "react-intl";
import Images from "../../assets";
import styles from "./styles.module.scss";

function TeamWork() {
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <div className={styles.image}>
        <img src={Images.TeamWork} alt="" />
      </div>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: "jobs_teamwork_title",
          })}
        </h3>
        <p className={styles.description}>
          {intl.formatMessage({
            id: "jobs_teamwork_description",
          })}
        </p>
      </div>
    </section>
  );
}

export default TeamWork;
