import React from "react";
import { cases } from "./constants";
import styles from "./styles.module.scss";

function Content() {
  return (
    <section className={styles.container}>
      {cases.map((item) => (
        <div key={item.title} className={styles.card}>
          <div className={styles.image}>
            <img src={item.image} alt="" loading="lazy" />
          </div>
          <div className={styles.wrapper}>
            <h3 className={styles.title}>{item.title}</h3>
            <p className={styles.text}>{item.text}</p>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Content;
