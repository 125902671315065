import styles from "./styles.module.scss";

function FramedImage({ src, alt = "" }) {
  return (
    <div className={styles.image}>
      <img src={src} alt={alt} loading="lazy" />
    </div>
  );
}

export default FramedImage;
