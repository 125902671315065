import { products } from "./constants";
import styles from "./styles.module.scss";

function Products() {
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>
        Promova a transformação digital de seus serviços com o Portal de Saúde:
      </h5>
      {products.map((product, index) => {
        return (
          <div key={index} className={styles.item}>
            {product.icon}
            <p className={styles.text}>{product.text}</p>
          </div>
        );
      })}
    </div>
  );
}

export default Products;
