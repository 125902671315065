import * as Accordion from "@radix-ui/react-accordion";
import styles from "./styles.module.scss";

function Accordions({
  items = [
    {
      title: "Title",
      content: "Content",
    },
  ],
}) {
  return (
    <Accordion.Root
      className={styles.container}
      type="single"
      defaultValue={1}
      collapsible
    >
      {items.map((item, i) => (
        <Accordion.Item className={styles.item} value={i + 1} key={i}>
          <Accordion.Header asChild>
            <Accordion.Trigger className={styles.trigger} asChild>
              <button>
                <p>{item.title}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 9L12 16L5 9"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className={styles.content}>
            {item.content}
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}

export default Accordions;
