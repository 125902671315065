import WhiteIcons from "../../assets/icons/white";
import { formatMessage } from "../../utils/formatMessage";

export const items = [
  {
    icon: <WhiteIcons.UserCheck />,
    title: formatMessage("outsourcing_businessbenefits_attraction_description"),
  },
  {
    icon: <WhiteIcons.SocialManagement />,
    title: formatMessage("outsourcing_businessbenefits_management_description"),
  },
  {
    icon: <WhiteIcons.ArrowsCycle />,
    title: formatMessage("outsourcing_businessbenefits_reduction_description"),
  },
  {
    icon: <WhiteIcons.HappyFace />,
    title: formatMessage(
      "outsourcing_businessbenefits_professionals_description"
    ),
  },
];
