import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Icons from "../../assets/icons";
import routesPaths from "../../routes/routesPaths";
import Button from "../Button";
import { items } from "./constants";
import styles from "./styles.module.scss";

function Carousel() {
  const [active, setActive] = useState(1);

  const carouselRef = useRef(null);

  const intl = useIntl();
  const navigate = useNavigate();

  const handleNext = () => {
    carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
    setActive(active + 1);
  };

  const handlePrev = () => {
    carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
    setActive(active - 1);
  };

  useEffect(() => {
    const handleResize = () => {
      carouselRef.current.scrollLeft = 0;
      setActive(1);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        {intl.formatMessage({ id: "carousel_title" })}
      </h3>
      <div className={styles.items}>
        <button
          onClick={handlePrev}
          className={styles.arrow}
          aria-label="Previous"
          disabled={active === 1}
        >
          <Icons.ArrowLeft />
        </button>
        <div className={styles.carousel} ref={carouselRef}>
          {items.map((item, i) => (
            <div className={styles.item} key={i}>
              <div className={styles.image}>
                <img src={item.image} alt="" loading="lazy" />
              </div>
              <div className={styles.wrapper}>
                <p className={styles.project}>{item.title}</p>
                <p className={styles.text}>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={handleNext}
          className={styles.arrow}
          aria-label="Next"
          disabled={active === items.length}
        >
          <Icons.ArrowRight />
        </button>
      </div>
      <div className={styles.button}>
        <Button
          text={intl.formatMessage({ id: "carousel_button" })}
          width="fit-content"
          onClick={() => {
            navigate(routesPaths.cases);
          }}
        />
      </div>
    </section>
  );
}

export default Carousel;
