import { ReactComponent as ArrowsCycle } from "./arrows-cycle.svg";
import { ReactComponent as Caution } from "./caution.svg";
import { ReactComponent as Chat } from "./chat.svg";
import { ReactComponent as HappyFace } from "./happy-face.svg";
import { ReactComponent as HotAirBalloon } from "./hot-air-balloon.svg";
import { ReactComponent as Internet } from "./internet.svg";
import { ReactComponent as Lamp } from "./lamp.svg";
import { ReactComponent as MentalHealth } from "./mental-health.svg";
import { ReactComponent as Pallette } from "./pallette.svg";
import { ReactComponent as PeopleTeam } from "./people-team.svg";
import { ReactComponent as Rocket } from "./rocket.svg";
import { ReactComponent as SocialManagement } from "./social-management.svg";
import { ReactComponent as Target } from "./target.svg";
import { ReactComponent as Trophy } from "./trophy.svg";
import { ReactComponent as UserBoard } from "./user-board.svg";
import { ReactComponent as UserChat } from "./user-chat.svg";
import { ReactComponent as UserCheck } from "./user-check.svg";
import { ReactComponent as Users } from "./users.svg";

const WhiteIcons = {
  Chat,
  Caution,
  HotAirBalloon,
  MentalHealth,
  Pallette,
  PeopleTeam,
  UserBoard,
  UserChat,
  Internet,
  Users,
  HappyFace,
  ArrowsCycle,
  UserCheck,
  SocialManagement,
  Rocket,
  Target,
  Lamp,
  Trophy,
};

export default WhiteIcons;
