import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRightCircle } from "./arrow-right-circle.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as Book } from "./book.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as Computer } from "./computer.svg";
import Map from "./map.webp";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as Quote } from "./quote.svg";
import { ReactComponent as SyncArrows } from "./sync-arrows.svg";

const Icons = {
  SyncArrows,
  Computer,
  Phone,
  ArrowLeft,
  ArrowRight,
  Map,
  ArrowRightCircle,
  Quote,
  Book,
  Clock,
};

export default Icons;
