import { ReactComponent as FacebookWhite } from "./facebook-white.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as InstagramWhite } from "./instagram-white.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as LinkedinWhite } from "./linkedin-white.svg";
import { ReactComponent as Linkedin } from "./linkedin.svg";
import { ReactComponent as StartaideiaWhite } from "./startaideia-white.svg";
import { ReactComponent as Startaideia } from "./startaideia.svg";
import { ReactComponent as TwitterWhite } from "./twitter-white.svg";
import { ReactComponent as YoutubeWhite } from "./youtube-white.svg";

import { default as Anjo } from "./anjo.webp";
import { default as Cabure } from "./cabure.webp";
import { default as CCG } from "./ccg.webp";
import { default as CiaDoSono } from "./cia-do-sono.webp";
import { default as Click } from "./click.webp";
import { default as ContaAgil } from "./conta-agil.webp";
import { default as HygiaBank } from "./hygia-bank.webp";
import { default as ProSer } from "./pro-ser.webp";
import { default as Sicredi } from "./sicredi.webp";
import { default as Snello } from "./snello.webp";
import { default as Tokio } from "./tokio.webp";

const Logos = {
  Facebook,
  FacebookWhite,
  Instagram,
  InstagramWhite,
  Linkedin,
  LinkedinWhite,
  TwitterWhite,
  YoutubeWhite,
  Startaideia,
  StartaideiaWhite,

  ContaAgil,
  Sicredi,
  Click,
  ProSer,
  CiaDoSono,
  HygiaBank,
  Snello,
  Anjo,
  CCG,
  Tokio,
  Cabure,
};

export default Logos;
