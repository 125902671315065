import { formatMessage } from "../../../../../../utils/formatMessage";

export const checklist = [
  formatMessage("healthProject_business_checklist_digital"),
  formatMessage("healthProject_business_checklist_ai"),
  formatMessage("healthProject_business_checklist_dps"),
  formatMessage("healthProject_business_checklist_signature"),
  formatMessage("healthProject_business_checklist_payment"),
  formatMessage("healthProject_business_checklist_broker"),
  formatMessage("healthProject_business_checklist_lgpd"),
  formatMessage("healthProject_business_checklist_tiss"),
];
