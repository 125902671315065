import { default as About } from "./about.webp";
import { default as BannerDesktop } from "./bannerDesktop.webp";
import { default as BannerMobile } from "./bannerMobile.webp";
import { default as Solutions } from "./solutions.webp";
import { default as Video } from "./video.gif";

const Images = {
  BannerDesktop,
  BannerMobile,
  About,
  Solutions,
  Video,
};

export default Images;
