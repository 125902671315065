import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { JOBS_STEPS } from "../../../constants/jobsSteps";
import ApplicantInfo from "./ApplicantInfo";
import Finish from "./Finish";
import JobInfo from "./JobInfo";
import styles from "./styles.module.scss";

function Modals({ step, setStep, modalData, setModalData }) {
  return (
    <>
      <AnimatePresence>
        {modalData.isOpen && (
          <Dialog
            open={modalData.isOpen}
            onClose={() => {
              setModalData({
                isOpen: false,
                slug: "",
                description: "",
                assignment: "",
                requirement: "",
                differentials: "",
                jobTitle: "",
              });
              setStep(1);
            }}
            className={styles.container}
            as={motion.div}
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3 }}
            key={modalData.slug}
          >
            <Dialog.Panel className={styles.panel}>
              <motion.div
                key={step}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={0.3}
                className={styles.panel}
              >
                {step === JOBS_STEPS.JOB_INFO && (
                  <JobInfo
                    step={step}
                    modalData={modalData}
                    setStep={setStep}
                    setModalData={setModalData}
                  />
                )}
                {step === JOBS_STEPS.APPLICANT_INFO && (
                  <ApplicantInfo
                    setStep={setStep}
                    jobTitle={modalData.jobTitle}
                  />
                )}
                {step === JOBS_STEPS.FINISH && (
                  <Finish setModalData={setModalData} setStep={setStep} />
                )}
              </motion.div>
            </Dialog.Panel>
          </Dialog>
        )}
      </AnimatePresence>
      {modalData.isOpen && <div className={styles.overlay} />}
    </>
  );
}

export default Modals;
