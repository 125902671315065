import React from "react";
import BusinessBenefits from "../../components/BusinessBenefits";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import TestimonialsCustomers from "../../components/TestimonialsCustomers";
import AboutSolutions from "./components/AboutSolutions";
import Banner from "./components/Banner";
import ContactUs from "./components/ContactUs";
import HowWeExecute from "./components/HowWeExecute";
import OurMethodology from "./components/OurMethodology";
import SecondaryBanner from "./components/SecondaryBanner";
import Solutions from "./components/Solutions";

function Outsourcing() {
  return (
    <>
      <Navbar />
      <main>
        <Banner />
        <Solutions />
        <SecondaryBanner />
        <AboutSolutions />
        <HowWeExecute />
        <OurMethodology />
        <BusinessBenefits />
        <TestimonialsCustomers />
        <ContactUs />
      </main>
      <Footer />
    </>
  );
}

export default Outsourcing;
