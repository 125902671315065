import React from "react";
import { useIntl } from "react-intl";
import useContact from "../../hooks/useContact";
import Alert from "../../components/Alert";
import Button from "../Button";
import Loader from "../Loader";
import styles from "./styles.module.scss";
import ReCAPTCHA from "react-google-recaptcha";

function FormContact() {
  const intl = useIntl();
  const {
    loading,
    alert,
    captchaRef,
    captchaWarning,
    message,
    sendData,
    setCaptchaWarning,
  } = useContact();

  return (
    <form id="contact-form" onSubmit={sendData} className={styles.form}>
      {alert.status && (
        <Alert type={alert.type} title={alert.title} text={alert.message} />
      )}
      <input
        type="text"
        name="name"
        className={styles.input}
        placeholder={intl.formatMessage({
          id: "contact_form_name",
        })}
        required
      />
      <input
        type="email"
        name="email"
        className={styles.input}
        placeholder={intl.formatMessage({
          id: "contact_form_email",
        })}
        required
      />
      <input
        type="text"
        name="phone"
        className={styles.input}
        placeholder={intl.formatMessage({
          id: "contact_form_phone",
        })}
        required
      />
      <textarea
        type="text"
        name="message"
        className={styles.input}
        placeholder={intl.formatMessage({
          id: "contact_form_message",
        })}
        required
      />
      <ReCAPTCHA
        ref={captchaRef}
        sitekey="6LdKkQMqAAAAAKTql9WdR4ACMxcyphI9kJqOw47S"
        onChange={() => setCaptchaWarning(false)}
      />
      {captchaWarning && (
        <i className={styles.error}>
          Atenção! É obrigatório o preenchimento do Captcha!
        </i>
      )}
      {loading ? (
        <Loader />
      ) : (
        <Button text={message} type="submit" className={styles.button} />
      )}
    </form>
  );
}

export default FormContact;
