import React from "react";
import { useIntl } from "react-intl";

import useIsMobile from "../../hooks/useIsMobile";
import Button from "../Button";
import styles from "./styles.module.scss";

function BannerLink({ button, title, background, backgroundMobile }) {
  const { isMobile } = useIsMobile();
  const intl = useIntl();

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: title,
          })}
        </h3>
        <a href={button.href}>
          <Button
            text={intl.formatMessage({
              id: button.text,
            })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M7.31956 1.11389C7.12872 0.914509 6.8191 0.914509 6.62796 1.11389C6.43698 1.31311 6.43698 1.63649 6.62796 1.83587L10.3319 5.70259H0.490617C0.219727 5.70259 0 5.93196 0 6.21477C0 6.49772 0.219727 6.72694 0.490617 6.72694H10.327L6.62305 10.5936C6.53116 10.6892 6.47953 10.8193 6.47953 10.9547C6.47953 11.0903 6.53116 11.22 6.62305 11.3158C6.71493 11.4125 6.84077 11.4661 6.97137 11.4643C7.10152 11.4637 7.22644 11.4104 7.31956 11.3158L11.8575 6.57857C11.9499 6.48201 12.0014 6.35095 12 6.21477C11.9991 6.07891 11.948 5.94899 11.8575 5.85129L7.31956 1.11389Z"
                fill="white"
              />
            </svg>
          </Button>
        </a>
      </div>
      <img
        src={isMobile ? backgroundMobile : background}
        alt=""
        className={styles.image}
        loading="lazy"
      />
    </section>
  );
}

export default BannerLink;
