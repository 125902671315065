import PinkIcons from "../../../../assets/icons/pink";
import { formatMessage } from "../../../../utils/formatMessage";

export const cards = [
  {
    icon: <PinkIcons.Chat />,
    title: formatMessage("jobs_startaculture_followup_title"),
    description: [
      formatMessage("jobs_startaculture_followup_description1"),
      formatMessage("jobs_startaculture_followup_description2"),
      formatMessage("jobs_startaculture_followup_description3"),
    ],
  },
  {
    icon: <PinkIcons.DocumentPerformance />,
    title: formatMessage("jobs_startaculture_evaluation_title"),
    description: [
      formatMessage("jobs_startaculture_evaluation_description1"),
      formatMessage("jobs_startaculture_evaluation_description2"),
    ],
  },
  {
    icon: <PinkIcons.UserTalk />,
    title: formatMessage("jobs_startaculture_talk_title"),
    description: [formatMessage("jobs_startaculture_talk_description")],
  },
  {
    icon: <PinkIcons.Coffee />,
    title: formatMessage("jobs_startaculture_coffee_title"),
    description: [formatMessage("jobs_startaculture_coffee_description")],
  },
  {
    icon: <PinkIcons.HotAirBalloon />,
    title: formatMessage("jobs_startaculture_flexibility_title"),
    description: [
      formatMessage("jobs_startaculture_flexibility_description1"),
      formatMessage("jobs_startaculture_flexibility_description2"),
      formatMessage("jobs_startaculture_flexibility_description3"),
    ],
  },
  {
    icon: <PinkIcons.Trophy />,
    title: formatMessage("jobs_startaculture_incentives_title"),
    description: [
      formatMessage("jobs_startaculture_incentives_description1"),
      formatMessage("jobs_startaculture_incentives_description2"),
      formatMessage("jobs_startaculture_incentives_description3"),
      formatMessage("jobs_startaculture_incentives_description4"),
    ],
  },
];
