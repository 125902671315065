import { ReactComponent as Bank } from "./bank.svg";
import { ReactComponent as BarChart } from "./bar-chart.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as Dollar } from "./dollar.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Flask } from "./flask.svg";
import { ReactComponent as HearthVitals } from "./hearth-vitals.svg";
import { ReactComponent as Hospital } from "./hospital.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as MedicalCross } from "./medical-cross.svg";
import { ReactComponent as Notebook } from "./notebook.svg";
import { ReactComponent as Puzzle } from "./puzzle.svg";
import { ReactComponent as Stethoscope } from "./stethoscope.svg";
import { ReactComponent as Trophy } from "./trophy.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as Wallet } from "./wallet.svg";

const BlueIcons = {
  Stethoscope,
  HearthVitals,
  Lock,
  Notebook,
  Bank,
  Dollar,
  Flask,
  MedicalCross,
  Hospital,
  Wallet,
  Check,
  BarChart,
  Puzzle,
  Users,
  Filter,
  Trophy,
};

export default BlueIcons;
