import { items } from "./constants";
import styles from "./styles.module.scss";

function OurSolutions() {
  return (
    <section className={styles.container}>
      <h3 className={styles.title}>Nossas soluções</h3>
      <div className={styles.items}>
        {items.map((item, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.wrapper}>
              {item.icon}
              <h4 className={styles.subtitle}>{item.title}</h4>
            </div>

            <p className={styles.description}>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default OurSolutions;
