import React from "react";
import { FormattedMessage } from "react-intl";
import useIsMobile from "../../../../hooks/useIsMobile";
import Images from "../../assets";
import styles from "./styles.module.scss";

function Banner() {
  const { isMobile } = useIsMobile();
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            <FormattedMessage
              id="cases_banner_title"
              defaultMessage="Descubra nossos cases de sucesso em soluções de software para empresas"
            />
          </h1>
          <h2 className={styles.description}>
            <FormattedMessage
              id="cases_banner_description"
              defaultMessage="Oferecemos soluções completas para empresas que desejam transformar o mundo por meio da tecnologia. Nosso portfólio inclui cases de sucesso com grande impacto tecnológico, comprovando nossa experiência e competência em ajudar empresas a alcançarem seus objetivos"
            />
          </h2>
        </div>
        {!isMobile && (
          <div className={styles.image}>
            <img src={Images.BannerLogo} alt="" />
          </div>
        )}
      </div>
      <div className={styles.backgroundImage}>
        <img
          src={isMobile ? Images.BackgroundMobile : Images.Background}
          alt=""
          loading="eager"
        />
      </div>
    </section>
  );
}

export default Banner;
