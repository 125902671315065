import React from "react";
import { useIntl } from "react-intl";
import { cards } from "./constants.js";
import styles from "./styles.module.scss";

function StartaCulture() {
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        {intl.formatMessage({
          id: "jobs_startaculture_title",
        })}
      </h3>
      <div className={styles.wrapper}>
        <p className={styles.description}>
          {intl.formatMessage({
            id: "jobs_startaculture_description",
          })}
        </p>
        <div className={styles.cards}>
          {cards.map((card, index) => {
            const { description, icon, title } = card;
            return (
              <div key={index} className={styles.card}>
                {icon}
                <h4 className={styles.cardTitle}>{title}</h4>
                {description.map((item, index) => {
                  return (
                    <p key={index} className={styles.item}>
                      {item}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default StartaCulture;
