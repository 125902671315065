import React from "react";
import { FormattedMessage } from "react-intl";
import useIsMobile from "../../../../hooks/useIsMobile";
import Images from "../../assets";
import styles from "./styles.module.scss";

function SecondaryBanner() {
  const { isMobile } = useIsMobile();
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          <FormattedMessage
            id="outsourcing_secondary_banner_title"
            defaultMessage="Garantimos um processo ágil e eficiente do 
              recrutamento ao acompanhamento dos profissionais. 
              Aceleramos sua transformação digital."
          />
        </h3>
      </div>
      <img
        src={isMobile ? Images.BackgroundMobile : Images.Background}
        alt=""
        className={styles.image}
        loading="lazy"
      />
    </section>
  );
}

export default SecondaryBanner;
