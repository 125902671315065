import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import styles from "./styles.module.scss";

function TextPage({ title, text }) {
  return (
    <>
      <Navbar />
      <main>
        <div className={styles.background}>
          <div className={styles.header}>
            <div className={styles.container}>
              <h1 className={styles.title}>{title}</h1>
            </div>
          </div>
        </div>
        <div className={`${styles.content} ${styles.container}`}>{text}</div>
      </main>
      <Footer />
    </>
  );
}

export default TextPage;
