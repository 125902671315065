import Banner from "./banner.webp";
import Caldeira1 from "./caldeira1.webp";
import Caldeira2 from "./caldeira2.webp";
import Feevale1 from "./feevale1.webp";
import Feevale2 from "./feevale2.webp";

const Images = {
  Banner,
  Feevale1,
  Feevale2,
  Caldeira1,
  Caldeira2,
};

export default Images;
