import React, { useContext } from "react";
import BannerLink from "../../components/BannerLink";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Player from "../../components/Player";
import { DataContext } from "../../context/DataContext";
import bannerLink from "./assets/bannerLink.webp";
import bannerLinkMobile from "./assets/bannerLinkMobile.webp";
import Banner from "./components/Banner";
import JobsList from "./components/JobsList";
import OurMantrasJobs from "./components/OurMantrasJobs";
import StartaCulture from "./components/StartaCulture";
import TeamWork from "./components/TeamWork";
import Testimonials from "./components/Testimonials";

function Jobs() {
  const { context } = useContext(DataContext);
  return (
    <>
      <Navbar />
      <main>
        <Banner />
        <TeamWork />
        <StartaCulture />
        <OurMantrasJobs />
        <Player />
        <Testimonials />
        <BannerLink
          background={bannerLink}
          backgroundMobile={bannerLinkMobile}
          button={{
            href: "https://www.linkedin.com/company/startaideia/mycompany/",
            text: "jobs_linkedinbanner_button",
          }}
          title="jobs_linkedinbanner_title"
        />
        {context === "pt" && <JobsList />}
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default Jobs;
