import { useState } from "react";
import { useIntl } from "react-intl";
import Accordions from "./components/Accordions";
import RadioGroup from "./components/RadioGroup";
import { accordions, options } from "./constants";
import styles from "./styles.module.scss";

function HowWeWork() {
  const [active, setActive] = useState("planning");
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <p className={styles.title}>
        {intl.formatMessage({
          id: "softwarefactory_howwework_title",
        })}
      </p>
      <div className={styles.wrapper}>
        <RadioGroup
          items={options}
          defaultValue="planning"
          value={active}
          onChange={setActive}
        />
        <Accordions items={accordions[active]} />
      </div>
    </section>
  );
}

export default HowWeWork;
