import styles from "./styles.module.scss";

function Loader({ variant = "primary" }) {
  return (
    <div className={styles.container}>
      <span className={`${styles.loader} ${styles[variant]}`} />
    </div>
  );
}

export default Loader;
