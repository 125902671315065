import Background from "./products-banner-image.svg";
import Araxingu from "./products-item-1.svg";
import HealthProject from "./products-item-2.svg";
import Open from "./products-item-3.svg";
import InsurOn from "./products-item-4.svg";
import ExoHub from "./exoHub.webp";

export const Images = {
  HealthProject,
  ExoHub,
  InsurOn,
  Open,
  Araxingu,
  Background,
};
