function ES() {
  return (
    <div>
      <h2>PLAN DE RESPUESTA A INCIDENTES DE SEGURIDAD</h2>
      <h3>
        Lea a continuación nuestro framework con planes y acciones relacionadas
        con incidentes de seguridad y privacidad de datos.
      </h3>

      <section>
        <h4>¿Qué es un incidente de seguridad?</h4>
        <p>
          Stataideia define un incidente de seguridad en sus servicios en línea
          como una violación confirmada de la seguridad que conduce a la
          destrucción, pérdida, alteración, divulgación no autorizada o acceso
          accidental o ilegal a datos del cliente o datos personales durante el
          procesamiento por parte de Startaideia.
        </p>
        <p>
          Por ejemplo, el acceso no autorizado a la infraestructura de servicios
          en línea de Strataideia y la exfiltración de datos del cliente
          constituirán un incidente de seguridad, mientras que los eventos de
          cumplimiento que no afecten la confidencialidad, integridad o
          disponibilidad de los servicios o datos del cliente no se consideran
          incidentes de seguridad.
        </p>
      </section>

      <section>
        <h4>¿Cómo responde Startaideia ante las incidencias de seguridad?</h4>
        <p>
          Siempre que se produce un incidente de seguridad, Startaideia se
          esfuerza por responder de forma rápida y eficaz para proteger los
          servicios de Startaideia y los datos de los clientes. Startaideia
          emplea una estrategia de respuesta a incidentes diseñada para
          investigar, contener y eliminar amenazas de seguridad de forma rápida
          y eficiente.
        </p>
        <p>
          Los servicios en la nube de Startaideia son monitoreados continuamente
          para detectar signos de compromiso. Además del monitoreo y alertas de
          seguridad automatizados, todos los empleados reciben capacitación
          anual para reconocer e informar señales de posibles incidentes de
          seguridad. Cualquier actividad sospechosa detectada por empleados,
          clientes o herramientas de monitoreo de seguridad se envía a los
          equipos de Respuesta de Seguridad específicos del Servicio para su
          investigación. Todos los equipos de operaciones de servicio, incluidos
          los equipos de respuesta de seguridad específicos del servicio,
          mantienen una profunda rotación de guardia para garantizar que los
          recursos estén disponibles para la respuesta a incidentes las 24 horas
          del día, los 7 días de la semana, los 365 días del año. Nuestras
          rotaciones de guardia permiten a Startaideia montar una respuesta
          eficaz a incidentes en cualquier momento o escala, incluidos eventos
          generalizados o simultáneos.
        </p>
        <p>
          Cuando se detecta y escala una actividad sospechosa, los equipos de
          Respuesta de Seguridad específicos del Servicio comienzan un proceso
          de análisis, contención, erradicación y recuperación. Estos equipos
          coordinan el análisis del posible incidente para determinar su
          alcance, incluido cualquier impacto en los clientes o en los datos de
          los clientes. Con base en este análisis, los equipos de respuesta de
          seguridad específicos del servicio trabajan con los equipos de
          servicio afectados para desarrollar un plan para contener la amenaza y
          minimizar el impacto del incidente, erradicar la amenaza del entorno y
          recuperarse completamente a un estado seguro conocido. Los equipos de
          servicio relevantes implementan el plan con el apoyo de equipos de
          respuesta de seguridad específicos del servicio para garantizar que la
          amenaza se elimine con éxito y que los servicios afectados se
          recuperen por completo.
        </p>
        <p>
          Una vez resuelto un incidente, los equipos de servicio implementan las
          lecciones aprendidas del incidente para prevenir, detectar y responder
          mejor a incidentes similares en el futuro. Algunos incidentes de
          seguridad, especialmente aquellos que afectan al cliente o resultan en
          una violación de datos, se someten a un análisis post-mortem completo.
          La autopsia está diseñada para identificar fallas técnicas, fallas de
          procedimiento, errores manuales y otras fallas de proceso que pueden
          haber contribuido al incidente o que fueron identificadas durante el
          proceso de respuesta al incidente. Las mejoras identificadas durante
          la autopsia se implementan con la coordinación de equipos de respuesta
          de seguridad específicos del servicio para ayudar a prevenir
          incidentes futuros y mejorar las capacidades de detección y respuesta.
        </p>
      </section>
      <section>
        <h4>Contacto</h4>
        <p>
          Para informar cualquier incidente de seguridad y privacidad de los
          datos, utilice nuestro{" "}
          <a href="https://startaideia.com.br/suporte">Soporte</a>.
        </p>
      </section>
    </div>
  );
}

export default ES;
