import { useIntl } from "react-intl";
import FormContact from "../../../../../../components/FormContact";
import styles from "./styles.module.scss";

function Form() {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {intl.formatMessage({ id: "softwarefactory_contact_form_title" })}
      </h3>
      <p className={styles.subtitle}>
        {intl.formatMessage({ id: "softwarefactory_contact_form_subtitle" })}
      </p>
      <FormContact />
    </div>
  );
}

export default Form;
