import React from "react";
import Logos from "../../../../assets/logos";
import styles from "./styles.module.scss";

function Footer() {
  return (
    <footer className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Logos.StartaideiaWhite />
          <p>+55 (51) 99387-2616 / contato@startaideia.com.br</p>
          <p>
            <span>Hub de Inovação Feevale Techpark</span> - R. Cândido Silveira,
            198 - Auxiliadora, Porto Alegre - RS, 90540-010
          </p>
          <p>
            Instituto Caldeira - Tv. São José, 455 - Navegantes, <br /> Porto
            Alegre - RS, 90240-200
          </p>
        </div>
        <a className={styles.back} href="#banner" referrerPolicy="no-referrer">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.33337 6.90788L8.00004 2.24121M8.00004 2.24121L12.6667 6.90788M8.00004 2.24121V14.2412"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Voltar para o início
        </a>
      </div>
    </footer>
  );
}

export default Footer;
