import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { JOBS_STEPS } from "../../../../constants/jobsSteps";
import ApplicantInfo from "../../Modals/ApplicantInfo";
import Finish from "../../Modals/Finish";
import styles from "./styles.module.scss";

function Modal({ modalOpen, setModalOpen, jobTitle }) {
  const [step, setStep] = useState(JOBS_STEPS.APPLICANT_INFO);
  return (
    <>
      <AnimatePresence>
        {modalOpen && (
          <Dialog
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
              setStep(1);
            }}
            className={styles.container}
            as={motion.div}
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.3 }}
          >
            <Dialog.Panel className={styles.panel}>
              <motion.div
                key={step}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={0.3}
                className={styles.panel}
              >
                {step === JOBS_STEPS.APPLICANT_INFO && (
                  <ApplicantInfo setStep={setStep} jobTitle={jobTitle} />
                )}
                {step === JOBS_STEPS.FINISH && (
                  <Finish setStep={setStep} setModalOpen={setModalOpen} />
                )}
              </motion.div>
            </Dialog.Panel>
          </Dialog>
        )}
      </AnimatePresence>
      {modalOpen && <div className={styles.overlay} />}
    </>
  );
}

export default Modal;
