import WhiteIcons from "../../../../../assets/icons/white";

export const items = [
  {
    icon: <WhiteIcons.Rocket />,
    title: "Experiência e conhecimento",
    text: "Somos especialistas em tecnologia e transformação digital com uma equipe altamente qualificada em diversas áreas, como desenvolvimento de software, design, marketing digital.",
  },
  {
    icon: <WhiteIcons.Lamp />,
    title: "Metodologia Inovadora",
    text: "Utilizamos tecnologias de ponta e metodologias ágeis no desenvolvimento de software para fornecer soluções personalizadas e eficientes.",
  },
  {
    icon: <WhiteIcons.Target />,
    title: "Foco no Cliente",
    text: "O nosso foco é promover a transformação digital dos nossos clientes de forma consistente, e para isso buscamos entender o cenário de cada case para atingirmos os melhores resultados.",
  },
  {
    icon: <WhiteIcons.Trophy />,
    title: "Propósito e Cultura",
    text: "Através de um propósito claro e de uma cultura forte de colaboração e pertencimento promovemos um ambiente de trabalho próspero, nossos profissionais estão motivados e engajados a entregar projetos de alta qualidade.",
  },
];
