import Logos from "../../../../assets/logos";

export const items = [
  { image: Logos.ContaAgil, name: "Logo Conta Ágil" },
  { image: Logos.Sicredi, name: "Logo Sicredi" },
  { image: Logos.Click, name: "Logo Click" },
  { image: Logos.ProSer, name: "Logo Pro Ser" },
  { image: Logos.CiaDoSono, name: "Logo Cia do Sono" },
  { image: Logos.HygiaBank, name: "Logo Hygia Bank" },
  { image: Logos.Snello, name: "Logo Snello" },
  { image: Logos.Anjo, name: "Logo Anjo" },
  { image: Logos.CCG, name: "Logo CCG" },
  { image: Logos.Tokio, name: "Logo Tokio Marine" },
];
