import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import Active from "./Active";
import Border from "./Border";
import styles from "./styles.module.scss";

function RadioGroup({
  value,
  onChange,
  items = [
    {
      value: "default",
      label: "Default",
    },
  ],
}) {
  return (
    <RadixRadioGroup.Root
      className={styles.container}
      value={value}
      onValueChange={(e) => onChange(e)}
    >
      {items?.map((item) => (
        <div className={styles.item} key={item.value}>
          <RadixRadioGroup.Item
            value={item.value}
            id={item.value}
            asChild
            aria-label={item.label}
          >
            <div className={styles.indicator}>
              <Border />
              <RadixRadioGroup.Indicator asChild>
                <Active />
              </RadixRadioGroup.Indicator>
            </div>
          </RadixRadioGroup.Item>
          <label className={styles.label} htmlFor={item.value}>
            {item.label}
          </label>
        </div>
      ))}
    </RadixRadioGroup.Root>
  );
}

export default RadioGroup;
