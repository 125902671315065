import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import Whatsapp from "../components/Whatsapp";
import routesPaths from "./routesPaths";

// Landing Page Journey
import About from "../views/About";
import Cases from "../views/Cases";
import HealthProject from "../views/HealthProject";
import Home from "../views/Home";
import Jobs from "../views/Jobs";
import Job from "../views/Jobs/Job";
import Outsourcing from "../views/Outsourcing";
import OutsourcingPageFeedback from "../views/OutsourcingPage/Feedback";
import OutsourcingPageHome from "../views/OutsourcingPage/Home";
import Politics from "../views/Politics";
import Products from "../views/Products";
import Security from "../views/Security";
import SoftwareFactory from "../views/SoftwareFactory";

function ExternalRedirect({ to }) {
  window.location.replace(to);
  return null;
}

function AppRoutes() {
  return (
    <Router>
      <Whatsapp />
      <ScrollToTop />
      <Routes>
        <Route path={routesPaths.home} element={<Home />} />
        <Route path={routesPaths.about} element={<About />} />
        <Route path={routesPaths.outsourcing} element={<Outsourcing />} />
        <Route path={routesPaths.cases} element={<Cases />} />
        <Route path={routesPaths.products} element={<Products />} />
        <Route path={routesPaths.healthProject} element={<HealthProject />} />
        <Route path={routesPaths.politics} element={<Politics />} />
        <Route
          path={routesPaths.softwareFactory}
          element={<SoftwareFactory />}
        />
        <Route path={routesPaths.security} element={<Security />} />
        <Route
          path={routesPaths.outsourcingHome}
          element={<OutsourcingPageHome />}
        />
        <Route
          path={routesPaths.outsourcingSuccess}
          element={<OutsourcingPageFeedback />}
        />
        <Route path={routesPaths.careers} element={<Jobs />} />
        <Route path={`${routesPaths.careers}/:slug`} element={<Job />} />
        <Route
          path={routesPaths.supportShortcut}
          element={<ExternalRedirect to={routesPaths.support} />}
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
