import WhiteIcons from "../../../../assets/icons/white";
import { formatMessage } from "../../../../utils/formatMessage";

export const items = [
  {
    icon: <WhiteIcons.UserChat />,
    text: formatMessage("jobs_ourmantras_feedback_description"),
  },
  {
    icon: <WhiteIcons.Chat />,
    text: formatMessage("jobs_ourmantras_comunication_description"),
  },
  {
    icon: <WhiteIcons.UserBoard />,
    text: formatMessage("jobs_ourmantras_learn_description"),
  },
  {
    icon: <WhiteIcons.Pallette />,
    text: formatMessage("jobs_ourmantras_exercise_description"),
  },
  {
    icon: <WhiteIcons.MentalHealth />,
    text: formatMessage("jobs_ourmantras_health_description"),
  },
  {
    icon: <WhiteIcons.HotAirBalloon />,
    text: formatMessage("jobs_ourmantras_freedom_description"),
  },
  {
    icon: <WhiteIcons.Caution />,
    text: formatMessage("jobs_ourmantras_afraid_description"),
  },
  {
    icon: <WhiteIcons.PeopleTeam />,
    text: formatMessage("jobs_ourmantras_count_description"),
  },
];
