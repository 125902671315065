import AgileSquads from "./agilesquads.webp";
import Background from "./background.webp";
import BackgroundMobile from "./backgroundMobile.webp";
import Hands from "./hands.webp";
import Outsourcing from "./outsourcing.webp";

const Images = {
  Background,
  Outsourcing,
  AgileSquads,
  BackgroundMobile,
  Hands,
};

export default Images;
