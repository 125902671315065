const routesPaths = {
  home: "/",
  about: "/sobre",
  products: "/produtos",
  outsourcing: "/outsourcing",
  softwareFactory: "/fabrica-de-software",
  cases: "/cases",
  careers: "/vagas",
  dataProtection: "/politica-de-protecao-de-dados",
  incidentResponse: "/plano-incidentes-seguranca",
  support: "https://startaideia.atlassian.net/servicedesk/customer/portals",
  healthProject: "/projeto-saude",
  politics: "/politica-de-protecao-de-dados",
  security: "/plano-incidentes-seguranca",
  outsourcingHome: "/outsourcing-tecnologia",
  outsourcingSuccess: "/outsourcing-tecnologia/sucesso",
  supportShortcut: "/suporte",
};

export default routesPaths;
