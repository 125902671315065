import { useIntl } from "react-intl";
import FramedImage from "../../../../components/FramedImage";
import Images from "../../assets";
import styles from "./styles.module.scss";

function AboutUs() {
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h3>
          {intl.formatMessage({
            id: "softwarefactory_about_title",
          })}
        </h3>
        <p>
          {intl.formatMessage({
            id: "softwarefactory_about_description",
          })}
        </p>
      </div>
      <div className={styles.image}>
        <FramedImage src={Images.AboutUs} alt="" />
      </div>
    </section>
  );
}

export default AboutUs;
