import React from "react";
import { ReactComponent as Chevron } from "../../../assets/comparison-arrow.svg";
import { directHiring, outsourcing } from "./constants";
import styles from "./styles.module.scss";

function Comparison() {
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.card}>
          <h3 className={styles.title}>Contratação Direta</h3>
          {directHiring.map((item) => (
            <div className={styles.item} key={item}>
              <Chevron className={styles.icon} />
              <p className={styles.text}>{item}</p>
            </div>
          ))}
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="52"
            viewBox="0 0 46 52"
            fill="none"
          >
            <path
              d="M33.7714 51.2715C33.1019 51.2715 32.4769 50.9366 32.1061 50.3792L24.1762 38.4565C23.3576 37.2257 21.5328 37.2776 20.7854 38.5529L13.9111 50.2828C13.5522 50.8952 12.8955 51.2715 12.1856 51.2715H2.98825C1.41399 51.2715 0.456877 49.537 1.29626 48.2052L14.8723 26.6645C15.2929 25.9972 15.282 25.145 14.8444 24.4887L1.0766 3.83694C0.190524 2.50783 1.14331 0.727539 2.7407 0.727539H12.2599C12.9294 0.727539 13.5544 1.06251 13.9252 1.61995L21.7095 13.324C22.5285 14.5554 24.3545 14.5027 25.1012 13.2261L31.8325 1.71777C32.1912 1.10448 32.8483 0.727539 33.5588 0.727539H42.756C44.3301 0.727539 45.2872 2.46171 44.4483 3.79355L31.0146 25.1187C30.5943 25.786 30.6053 26.638 31.0427 27.2941L44.9547 48.1621C45.8408 49.4913 44.888 51.2715 43.2906 51.2715H33.7714Z"
              fill="#0D152E"
            />
          </svg>
        </div>
        <div className={styles.card}>
          <h3 className={styles.title}>Outsourcing</h3>
          {outsourcing.map((item) => (
            <div className={styles.item} key={item}>
              <Chevron className={styles.icon} />
              <p className={styles.text}>{item}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Comparison;
