import React from "react";
import Logos from "../../../assets/logos";
import Background from "../assets/feedback.webp";
import { ReactComponent as Check } from "../assets/success.svg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import styles from "./styles.module.scss";

function Feedback() {
  return (
    <div div className={styles.flex}>
      <Navbar />
      <main className={styles.background}>
        <div className={styles.container}>
          <Logos.StartaideiaWhite className={styles.logo} />
          <Check className={styles.check} />
          <h1 className={styles.title}>Recebemos seu interesse!</h1>
          <h2 className={styles.subtitle}>
            Logo o nosso time comercial entrará em contato para marcar um
            bate-papo.
          </h2>
        </div>
        <img src={Background} alt="" className={styles.image} />
      </main>
      <Footer />
    </div>
  );
}

export default Feedback;
