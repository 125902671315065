export const directHiring = [
  "Processo de contratação demorado e baixa assertividade;",
  "Obrigações trabalhistas e previdenciárias;",
  "Custos mais elevados;",
  "Problemas com gestão de equipe;",
  "Alto investimento em treinamento e capacitação.",
];

export const outsourcing = [
  "Processo de contratação de talentos qualificados;",
  "Empresa externa é responsável por obrigações trabalhistas;",
  "Flexibilidade e atendimento sob demanda;",
  "Gestão de pessoas otimizada;",
  "Acesso a profissionais altamente especializados.",
];
