import Icons from "../../../../assets/icons";
import { formatMessage } from "../../../../utils/formatMessage";

export const cards = [
  {
    icon: <Icons.SyncArrows />,
    title: formatMessage("softwarefactory_ourservices_card_migrations_title"),
    description: formatMessage(
      "softwarefactory_ourservices_card_migrations_description"
    ),
  },
  {
    icon: <Icons.Computer />,
    title: formatMessage("softwarefactory_ourservices_card_software_title"),
    description: formatMessage(
      "softwarefactory_ourservices_card_software_description"
    ),
  },
  {
    icon: <Icons.Phone />,
    title: formatMessage("softwarefactory_ourservices_card_mobile_title"),
    description: formatMessage(
      "softwarefactory_ourservices_card_mobile_description"
    ),
  },
];
