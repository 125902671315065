import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Icons from "../../assets/icons";
import { items } from "./constants";
import styles from "./styles.module.scss";

function TestimonialsCustomers() {
  const carouselRef = useRef(null);

  const [count, setCount] = useState(1);
  const [disabledArrow, setDisabledArrow] = useState("prev");

  const itemWidth = window.innerWidth > 500 ? 420 : window.innerWidth - 64;

  const handleNext = () => {
    setCount((prev) => prev + 1);
    carouselRef.current.scrollLeft += itemWidth;

    const itemsDisplayed = Math.floor(
      carouselRef.current.offsetWidth / itemWidth
    );

    const isLastItem = items.length - count === itemsDisplayed;
    setDisabledArrow(isLastItem ? "next" : null);
  };

  const handlePrev = () => {
    setDisabledArrow(count === 2 ? "prev" : null);
    setCount((prev) => prev - 1);

    carouselRef.current.scrollLeft -= itemWidth;
  };

  useEffect(() => {
    const handleResize = () => {
      carouselRef.current.scrollLeft = 0;
      setCount(1);
      setDisabledArrow("prev");
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section>
      <div className={styles.container}>
        <h3 className={styles.title}>
          <FormattedMessage
            id="outsourcing_testimonialscustomers_title"
            defaultMessage="Depoimento de nossos clientes"
          />
        </h3>
        <div className={styles.action}>
          <button
            className={styles.arrow}
            aria-label="Previous"
            disabled={disabledArrow === "prev"}
            onClick={handlePrev}
          >
            <Icons.ArrowRightCircle />
          </button>
          <button
            className={styles.arrow}
            aria-label="Next"
            disabled={disabledArrow === "next"}
            onClick={handleNext}
          >
            <Icons.ArrowRightCircle />
          </button>
        </div>
      </div>
      <div className={styles.carousel} ref={carouselRef}>
        {items.map((item, i) => (
          <div className={styles.card} key={i}>
            <p>
              <FormattedMessage id={item} />
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TestimonialsCustomers;
