import React from "react";
import { useIntl } from "react-intl";
import { items } from "./constants";
import styles from "./styles.module.scss";

function OurMantrasJobs() {
  const intl = useIntl();

  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        {intl.formatMessage({ id: "jobs_ourmantras_title" })}
      </h3>
      <div className={styles.wrapper}>
        {items.map((item, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.icon}>{item.icon}</div>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default OurMantrasJobs;
