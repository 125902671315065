import { useIntl } from "react-intl";
import { items } from "./constants";
import styles from "./styles.module.scss";

function Services() {
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: "healthProject_services_title",
          })}
        </h3>
        <p className={styles.description}>
          {intl.formatMessage({
            id: "healthProject_services_description",
          })}
        </p>
        <p className={styles.text}>
          {intl.formatMessage({
            id: "healthProject_services_text",
          })}
        </p>
      </div>
      <div className={styles.items}>
        {items.map((item) => (
          <div className={styles.item} key={item.text}>
            {item.icon}
            <p className={styles.itemTitle}>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
