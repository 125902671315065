import { PopupButton } from "react-calendly";
import { useIntl } from "react-intl";
import Images from "../../assets";
import styles from "./styles.module.scss";

function Demonstration() {
  const intl = useIntl();
  return (
    <section className={styles.container} id="demonstration">
      <h3 className={styles.title}>
        {intl.formatMessage({
          id: "healthProject_demonstration_title",
        })}
      </h3>
      <div className={styles.content}>
        <img src={Images.Video} alt="" />
      </div>
      <PopupButton
        url="https://calendly.com/startaideia/demonstracao-portal-de-saude"
        rootElement={document.getElementById("root")}
        text={intl.formatMessage({
          id: "healthProject_demonstration_button",
        })}
        className={styles.button}
      />
    </section>
  );
}

export default Demonstration;
