import { Link } from "react-router-dom";
import PinkIcons from "../../../../assets/icons/pink";
import { products } from "./constants";
import styles from "./styles.module.scss";

function Content() {
  return (
    <section className={styles.container}>
      {products.map((product) => (
        <div className={styles.card} key={product.id}>
          <div className={styles.image}>
            <img
              src={product.image}
              alt=""
              loading="lazy"
              width={460}
              height={310}
            />
          </div>
          <div className={styles.wrapper}>
            <h3 className={styles.title}>{product.title}</h3>
            <p className={styles.text}>{product.text}</p>
            {product.link && (
              <Link to={product.link} className={styles.link}>
                Saiba mais <PinkIcons.ArrowRight />
              </Link>
            )}
          </div>
        </div>
      ))}
    </section>
  );
}

export default Content;
