import styles from "./styles.module.scss";
export default function Active() {
  return (
    <svg
      width="33"
      height="37"
      viewBox="0 0 33 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.active}
    >
      <path
        d="M30 13.3039C34 15.6133 34 21.3868 30 23.6962L9 35.8205C5 38.1299 -1.86836e-06 35.2432 -1.66647e-06 30.6244L-6.06524e-07 6.37565C-4.0463e-07 1.75684 5 -1.12991 9 1.17949L30 13.3039Z"
        fill="url(#paint0_linear_4257_595)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4257_595"
          x1="34.199"
          y1="-38.2069"
          x2="-156.449"
          y2="231.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CE0C9A" />
          <stop offset="0.1865" stopColor="#D82E78" />
          <stop offset="0.4246" stopColor="#E35353" />
          <stop offset="0.6462" stopColor="#EB6D38" />
          <stop offset="0.8429" stopColor="#F07D28" />
          <stop offset="0.9944" stopColor="#F28322" />
        </linearGradient>
      </defs>
    </svg>
  );
}
