import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PinkIcons from "../../../../assets/icons/pink";
import Loader from "../../../../components/Loader";
import { JOBS_STEPS } from "../../../../constants/jobsSteps";
import Modals from "../../Modals";
import styles from "./styles.module.scss";

function JobsList() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modalData, setModalData] = useState({
    isOpen: false,
    slug: "",
    description: "",
    assignment: "",
    requirement: "",
    differentials: "",
    jobTitle: "",
  });

  const [step, setStep] = useState(JOBS_STEPS.JOB_INFO);

  const intl = useIntl();

  const getData = async () => {
    try {
      setLoading(true);
      const data = await axios.get(
        "https://admin.carreiras.startaideia.dev/api/jobs"
      );
      setJobs(data.data.jobs);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOpenModal = (job) => {
    setModalData({
      isOpen: true,
      description: job.job_description,
      assignment: job.job_assignment,
      requirement: job.job_requirement,
      differentials: job.job_differentials,
      slug: job.slug,
      jobTitle: job.title,
    });
  };

  if (loading)
    return (
      <section id="jobs" className={styles.container}>
        <Loader variant="secondary" />
      </section>
    );

  return (
    <>
      <section id="jobs" className={styles.background}>
        <div className={styles.container}>
          <h3 className={styles.title}>
            {intl.formatMessage({ id: "jobs_opportunities_title" })}
          </h3>

          {jobs.length === 0 ? (
            <p className={styles.notFound}>
              No momento não estamos com nenhuma vaga em aberto. Mas fique de
              olho em nossas redes para acompanhar as novidades e não perder
              nenhuma oportunidade!
            </p>
          ) : (
            <div className={styles.jobs}>
              {jobs.map((job) => (
                <button
                  className={styles.card}
                  onClick={() => handleOpenModal(job)}
                  key={job.id}
                >
                  <div className={styles.content}>
                    <h4>{job.title}</h4>
                    <div className={styles.wrapper}>
                      <p>
                        <PinkIcons.Location />
                        {job.modality}
                      </p>
                      <p>
                        <PinkIcons.Clock /> {job.workload}
                      </p>
                    </div>
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className={styles.arrow}
                  >
                    <path
                      d="M16.172 10.9997L10.808 5.63568L12.222 4.22168L20 11.9997L12.222 19.7777L10.808 18.3637L16.172 12.9997H4V10.9997H16.172Z"
                      fill="black"
                    />
                  </svg>
                </button>
              ))}
            </div>
          )}
        </div>
      </section>

      <Modals
        step={step}
        setStep={setStep}
        modalData={modalData}
        setModalData={setModalData}
      />
    </>
  );
}

export default JobsList;
