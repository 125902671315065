import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

function Slides({ items, children }) {
  const [active, setActive] = useState(0);

  const carouselRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setActive(0);
      carouselRef.current.scrollLeft = 0;
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (index) => {
    if (index === items.length) return;
    const spacing = 16 * index;

    setActive(index);
    carouselRef.current.scrollLeft =
      index * carouselRef.current.offsetWidth + spacing;
  };

  return (
    <>
      <div className={styles.carousel} ref={carouselRef}>
        {children}
      </div>
      <div className={styles.dots}>
        {items.map((_, index) => (
          <button
            className={active === index ? styles.activeDot : styles.dot}
            key={index}
            onClick={() => handleChange(index)}
            aria-label={`Go to testimonial ${index + 1}`}
          />
        ))}
      </div>
    </>
  );
}

export default Slides;
