import { formatMessage } from "../../../../utils/formatMessage";

export const stats = [
  {
    number: 80,
    text: formatMessage("softwarefactory_stats_clients"),
  },
  {
    number: 20,
    text: formatMessage("softwarefactory_stats_projects"),
    quantity: "mi",
  },
  {
    number: 1,
    text: formatMessage("softwarefactory_stats_users"),
    quantity: "mi",
  },
];
