import { formatMessage } from "../../../../utils/formatMessage";

export const items = [
  {
    title: formatMessage("outsourcing_contactus_faq_questionone"),
    content: formatMessage("outsourcing_contactus_faq_answerone"),
  },
  {
    title: formatMessage("outsourcing_contactus_faq_questiontwo"),
    content: formatMessage("outsourcing_contactus_faq_answertwo"),
  },
  {
    title: formatMessage("outsourcing_contactus_faq_questionthree"),
    content: formatMessage("outsourcing_contactus_faq_answerthree"),
  },
];
