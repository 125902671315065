import BlueIcons from "../../../../assets/icons/blue";
import { formatMessage } from "../../../../utils/formatMessage";

export const items = [
  {
    icon: <BlueIcons.Stethoscope />,
    title: formatMessage("healthProject_solutions_healthPlan_title"),
    text: formatMessage("healthProject_solutions_healthPlan_description"),
  },
  {
    icon: <BlueIcons.HearthVitals />,
    title: formatMessage("healthProject_solutions_plans_title"),
    text: formatMessage("healthProject_solutions_plans_description"),
  },
  {
    icon: <BlueIcons.Notebook />,
    title: formatMessage("healthProject_solutions_simulator_title"),
    text: formatMessage("healthProject_solutions_simulator_description"),
  },
  {
    icon: <BlueIcons.Lock />,
    title: formatMessage("healthProject_solutions_dps_title"),
    text: formatMessage("healthProject_solutions_dps_description"),
  },
];
