import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../../../components/Button";
import useIsMobile from "../../../../hooks/useIsMobile";
import styles from "./styles.module.scss";

import bgMobile from "../../../../assets/images/outsourcing-page-banner-image-mobile.webp";
import bg from "../../../../assets/images/outsourcing-page-banner-image.webp";

function Banner() {
  const intl = useIntl();
  const { isMobile } = useIsMobile();

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            <FormattedMessage
              id="outsourcing_banner_title"
              defaultMessage="Outsourcing"
            />
          </h1>
          <h2 className={styles.description}>
            <FormattedMessage
              id="outsourcing_banner_description"
              defaultMessage="Encontramos profissionais altamente qualificados para desenvolver o seu negócio."
            />
          </h2>
          <a href="#contact">
            <Button
              text={intl.formatMessage({
                id: "outsourcing_banner_button",
              })}
            />
          </a>
        </div>
        <img src={isMobile ? bgMobile : bg} alt="" className={styles.image} />
      </div>
    </section>
  );
}

export default Banner;
