import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import FramedImage from "../../../../components/FramedImage";
import Images from "../../assets";
import styles from "./styles.module.scss";

function AboutSolutions() {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(1);

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: "outsourcing_aboutsolutions_title",
          })}
        </h3>
        <div className={styles.tabs}>
          <button
            className={`${styles.tab} ${activeTab === 1 && styles.active}`}
            onClick={() => setActiveTab(1)}
          >
            Outsourcing
          </button>
          <button
            className={`${styles.tab} ${activeTab === 2 && styles.active}`}
            onClick={() => setActiveTab(2)}
          >
            {intl.formatMessage({
              id: "outsourcing_solutions_agilesquads_title",
            })}
          </button>
        </div>
        <AnimatePresence mode="wait">
          {activeTab === 1 && (
            <motion.div
              className={styles.content}
              key="tab1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className={styles.wrapper}>
                <h4 className={styles.contentTitle}>
                  {intl.formatMessage({
                    id: "outsourcing_aboutsolutions_outsourcing_title",
                  })}
                </h4>
                <p className={styles.contentText}>
                  {intl.formatMessage({
                    id: "outsourcing_aboutsolutions_outsourcing_description",
                  })}
                </p>
              </div>
              <div className={styles.image}>
                <FramedImage src={Images.Outsourcing} />
              </div>
            </motion.div>
          )}
          {activeTab === 2 && (
            <motion.div
              key="tab2"
              className={styles.content}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className={styles.wrapper}>
                <h4 className={styles.contentTitle}>
                  {intl.formatMessage({
                    id: "outsourcing_aboutsolutions_agilesquads_title",
                  })}
                </h4>
                <p className={styles.contentText}>
                  {intl.formatMessage({
                    id: "outsourcing_aboutsolutions_agilesquads_description",
                  })}
                </p>
              </div>
              <div className={styles.image}>
                <FramedImage src={Images.AgileSquads} />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
}
export default AboutSolutions;
