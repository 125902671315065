import CountUp from "react-countup";
import { stats } from "./constants";
import styles from "./styles.module.scss";

function Stats() {
  return (
    <section className={styles.container}>
      {stats.map((item, index) => {
        return (
          <div className={styles.content} key={index}>
            <p className={styles.number}>
              +<CountUp end={item.number} />
              {item?.quantity}
            </p>
            <p className={styles.text}>{item.text}</p>
          </div>
        );
      })}
    </section>
  );
}

export default Stats;
