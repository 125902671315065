import React, { useContext } from "react";
import TextPage from "../../components/TextPage";
import { DataContext } from "../../context/DataContext";
import EN from "./Texts/en";
import ES from "./Texts/es";
import PT from "./Texts/pt";

function Politics() {
  const { context } = useContext(DataContext);

  const title = {
    pt: "Política de Privacidade",
    es: "Política de Privacidad",
    en: "Privacy Policy",
  };

  const texts = {
    pt: <PT />,
    es: <ES />,
    en: <EN />,
  };

  return <TextPage title={title[context]} text={texts[context]} />;
}

export default Politics;
