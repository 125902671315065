export default function Border() {
  return (
    <svg
      width="50"
      height="55"
      viewBox="0 0 50 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.75 21.0048C48.75 23.8916 48.75 31.1084 43.75 33.9952L13.75 51.3157C8.75001 54.2025 2.5 50.594 2.50001 44.8205L2.50001 10.1795C2.50001 4.406 8.75001 0.797551 13.75 3.6843L43.75 21.0048Z"
        stroke="url(#paint0_linear_4257_591)"
        strokeWidth="5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4257_591"
          x1="52.6139"
          y1="-59.7413"
          x2="-240.691"
          y2="354.638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CE0C9A" />
          <stop offset="0.1865" stopColor="#D82E78" />
          <stop offset="0.4246" stopColor="#E35353" />
          <stop offset="0.6462" stopColor="#EB6D38" />
          <stop offset="0.8429" stopColor="#F07D28" />
          <stop offset="0.9944" stopColor="#F28322" />
        </linearGradient>
      </defs>
    </svg>
  );
}
