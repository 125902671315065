import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Banner from "./components/Banner";
import Content from "./components/Content";

function Products() {
  return (
    <>
      <Navbar />
      <main>
        <Banner />
        <Content />
      </main>
      <Footer />
    </>
  );
}

export default Products;
