import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

import Images from "../../assets";

function Banner() {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          <FormattedMessage
            id="about_banner_title"
            defaultMessage="Transformamos o mundo através da tecnologia e soluções inteligentes"
          />
        </h1>
        <h2 className={styles.description}>
          <FormattedMessage
            id="about_banner_description"
            defaultMessage="Somos uma empresa comprometida com a transformação através da
            tecnologia e do trabalho colaborativo. Fazemos isso através de
            soluções criativas e inteligentes que auxiliam empreendedores a
            materializar e evoluir seus projetos."
          />
        </h2>
      </div>
      <div className={styles.image}>
        <img src={Images.Banner} alt="" />
      </div>
    </section>
  );
}

export default Banner;
