/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import BannerLink from "../../components/BannerLink";
import Carousel from "../../components/Carousel";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

import background from "./assets/bannerContact.webp";
import backgroundMobile from "./assets/bannerContactMobile.webp";

import Banner from "./components/Banner";
import Clients from "./components/Clients";
import Solutions from "./components/Solutions";
import Stats from "./components/Stats";
import Testimonials from "./components/Testimonials";
import Blog from "./components/Blog";

function Home() {
  return (
    <>
      <Navbar />
      <main>
        <Banner />
        <Stats />
        <Solutions />
        <Clients />
        <Carousel />
        <BannerLink
          background={background}
          backgroundMobile={backgroundMobile}
          button={{
            href: "#contact",
            text: "home_bannerContact_button",
          }}
          title="home_bannerContact_title"
        />
        <Testimonials />
        <Blog />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default Home;
