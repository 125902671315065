import React from "react";
import bgMobile from "../../../../../assets/images/outsourcing-page-banner-image-mobile.webp";
import bgDesktop from "../../../../../assets/images/outsourcing-page-banner-image.webp";
import Logos from "../../../../../assets/logos";
import useContact from "../../../../../hooks/useContact";
import useIsMobile from "../../../../../hooks/useIsMobile";
import styles from "./styles.module.scss";

import { useNavigate } from "react-router-dom";

import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import routesPaths from "../../../../../routes/routesPaths";

function Banner() {
  const { isMobile } = useIsMobile();
  const { loading, message, sendData } = useContact();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await sendData(event);
    navigate(routesPaths.outsourcingSuccess);
  };

  return (
    <section className={styles.background} id="banner">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Logos.StartaideiaWhite />
          <h1 className={styles.title}>
            Encontre os melhores profissionais de tecnologia para sua empresa
          </h1>
          <h2 className={styles.subtitle}>
            O Outsourcing da Startaideia é a combinação perfeita entre
            tecnologia avançada e profissionais altamente qualificados para
            impulsionar a eficiência e o sucesso da sua empresa. Simplifique o
            dia a dia dos seus negócios e maximize os resultados.
          </h2>
        </div>
        <form className={styles.form} onSubmit={(event) => handleSubmit(event)}>
          <fieldset>
            <legend className={styles.legend}>Quero Contratar!</legend>
            <p className={styles.text}>
              Nossa equipe está a disposição para apresentar as soluções em
              Outsourcing da Starta.
            </p>
            <input
              type="text"
              name="name"
              className={styles.input}
              placeholder="Informe seu nome"
              required
            />
            <input
              type="email"
              name="email"
              className={styles.input}
              placeholder="Seu e-mail"
              required
            />
            <input
              type="text"
              name="phone"
              className={styles.input}
              placeholder="Número de telefone"
              required
            />
            <textarea
              type="text"
              name="message"
              className={styles.input}
              placeholder="Deixe aqui sua mensagem"
              required
            />
          </fieldset>
          {loading ? (
            <Loader />
          ) : (
            <Button text={message} type="submit" className={styles.button} />
          )}
        </form>
      </div>
      <img
        src={isMobile ? bgMobile : bgDesktop}
        alt=""
        className={styles.image}
      />
    </section>
  );
}

export default Banner;
