import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import Flags from "../../../assets/flags";
import { DataContext } from "../../../context/DataContext";
import styles from "./styles.module.scss";

function Language() {
  const [open, setOpen] = useState(false);
  const { context, handleData } = useContext(DataContext);

  function handleSelect(item) {
    handleData(item);
    setOpen(false);
  }
  const intl = useIntl();

  return (
    <div className={styles.container} onMouseLeave={() => setOpen(false)}>
      <button
        className={styles.button}
        onClick={() => setOpen(!open)}
        aria-haspopup="true"
        aria-expanded={open}
        tabIndex={0}
      >
        <span>{context}</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={open ? styles.open : ""}
        >
          <path
            d="M12.6666 5.99988L7.99998 10.6665L3.33331 5.99988"
            stroke="#37474F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {open && (
        <div className={styles.select} role="menu">
          <button
            onClick={() => handleSelect("pt")}
            role="menuitem"
            aria-label={intl.formatMessage({
              id: "navbar_portuguese",
            })}
          >
            <img src={Flags.Brazil} className={styles.flag} alt="" />
            PT
          </button>
          <button
            onClick={() => handleSelect("en")}
            role="menuitem"
            aria-label={intl.formatMessage({
              id: "navbar_english",
            })}
          >
            <img src={Flags.USA} className={styles.flag} alt="" />
            EN
          </button>
          <button
            onClick={() => handleSelect("es")}
            role="menuitem"
            aria-label={intl.formatMessage({
              id: "navbar_spanish",
            })}
          >
            <img src={Flags.Spain} className={styles.flag} alt="" />
            ES
          </button>
        </div>
      )}
    </div>
  );
}

export default Language;
