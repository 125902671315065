import BlueIcons from "../../../../assets/icons/blue";
import { formatMessage } from "../../../../utils/formatMessage";

export const items = [
  {
    icon: <BlueIcons.Hospital />,
    text: formatMessage("healthProject_services_hospitals"),
  },
  {
    icon: <BlueIcons.Flask />,
    text: formatMessage("healthProject_services_clinics"),
  },
  {
    icon: <BlueIcons.MedicalCross />,
    text: formatMessage("healthProject_services_healthOperators"),
  },
  {
    icon: <BlueIcons.Dollar />,
    text: formatMessage("healthProject_services_creditCooperatives"),
  },
  {
    icon: <BlueIcons.Bank />,
    text: formatMessage("healthProject_services_financialInstitutions"),
  },
  {
    icon: <BlueIcons.Wallet />,
    text: formatMessage("healthProject_services_brokerages"),
  },
];
