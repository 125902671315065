import { formatMessage } from "../../../../utils/formatMessage";

export const options = [
  {
    value: "planning",
    label: formatMessage("softwarefactory_howwework_planning", "Planejamento"),
  },
  {
    value: "construction",
    label: formatMessage(
      "softwarefactory_howwework_construction",
      "Construção"
    ),
  },
  {
    value: "sustenance",
    label: formatMessage("softwarefactory_howwework_sustenance", "Sustentação"),
  },
];

export const accordions = {
  planning: [
    {
      title: formatMessage(
        "softwarefactory_howwework_planning_challenge_title",
        "Entendimento do desafio"
      ),
      content: formatMessage(
        "softwarefactory_howwework_planning_challenge_description",
        "Essa etapa refere-se ao processo de compreender completamente as necessidades e objetivos do projeto. Envolve análise cuidadosa dos requisitos e restrições do negócio, bem como a identificação de obstáculos técnicos. Por meio de uma abordagem colaborativa, trabalhamos em estreita colaboração com o cliente para garantir que todos os aspectos dos desafios sejam claramente entendidos."
      ),
    },
    {
      title: formatMessage(
        "softwarefactory_howwework_planning_requirements_title",
        "Mapeamento de requisitos"
      ),
      content: formatMessage(
        "softwarefactory_howwework_planning_requirements_description",
        "Nesta etapa do processo identificamos, documentamos e compreendemos informações relevantes como requisitos funcionais e não funcionais. Essas informações são utilizadas para orientar o desenvolvimento do produto, definindo os recursos, funcionalidades e características que devem ser implementados."
      ),
    },
    {
      title: formatMessage(
        "softwarefactory_howwework_planning_design_title",
        "Arquitetura e Design"
      ),
      content: formatMessage(
        "softwarefactory_howwework_planning_design_description",
        "É feita uma análise minuciosa levando em consideração fatores como desempenho, escalabilidade, segurança e usabilidade. Com base nessa análise, são definidas as diretrizes arquiteturais, escolhendo as tecnologias e plataformas mais adequadas para a solução. Em seguida, a equipe de design utiliza essas diretrizes para criar interfaces e experiências de usuário intuitivas e eficientes."
      ),
    },
  ],
  construction: [
    {
      title: formatMessage(
        "softwarefactory_howwework_construction_development_title",
        "Desenvolvimento"
      ),
      content: formatMessage(
        "softwarefactory_howwework_construction_development_description",
        "Nesta fase, os desenvolvedores utilizam as informações levantadas nas etapas anteriores para criar a estrutura e a lógica do software. São utilizadas linguagens de programação, frameworks e bibliotecas adequadas para o projeto, levando em consideração a eficiência, a escalabilidade e a manutenção do código. Seguimos práticas mais adequadas de codificação, de acordo com o desafio e padrões acordados com o cliente."
      ),
    },
    {
      title: formatMessage(
        "softwarefactory_howwework_construction_testing_title",
        "Testes"
      ),

      content: formatMessage(
        "softwarefactory_howwework_construction_testing_description",
        "É feita uma análise minuciosa levando em consideração fatores como desempenho, escalabilidade, segurança e usabilidade. Com base nessa análise, são definidas as diretrizes arquiteturais, escolhendo as tecnologias e plataformas mais adequadas para a solução. Em seguida, a equipe de design utiliza essas diretrizes para criar interfaces e experiências de usuário intuitivas e eficientes."
      ),
    },
    {
      title: formatMessage(
        "softwarefactory_howwework_construction_homolog_title",
        "Homologação"
      ),
      content: formatMessage(
        "softwarefactory_howwework_construction_homolog_description",
        "Nessa fase, são realizadas atividades de validação pelo time do cliente para garantir que a solução atenda aos requisitos estabelecidos inicialmente e esteja pronta para uso. Isso envolve uma revisão da documentação técnica, regras de negócio e cenários de testes práticos de uso, a fim de verificar se a solução está completa e precisa."
      ),
    },
  ],
  sustenance: [
    {
      title: formatMessage(
        "softwarefactory_howwework_sustenance_trainning_title",
        "Treinamento e Documentação"
      ),
      content: formatMessage(
        "softwarefactory_howwework_sustenance_trainning_description",
        "A fase de treinamento e documentação é fundamental para garantir que os sistemas e soluções sejam devidamente utilizados e mantidos pelos usuários e equipe de suporte. Durante essa fase, são desenvolvidos materiais de treinamento, como manuais do usuário, tutoriais e guias de referência, que fornecem instruções detalhadas sobre a operação e funcionalidades dos sistemas."
      ),
    },
    {
      title: formatMessage(
        "softwarefactory_howwework_sustenance_maintenance_title",
        "Manutenção e Evolução"
      ),
      content: formatMessage(
        "softwarefactory_howwework_sustenance_maintenance_description",
        "Essa etapa compreende as atividades contínuas de suporte, correção de problemas e aprimoramento dos sistemas e soluções implantados. A equipe de suporte técnico monitora e gerencia os sistemas em produção, respondendo a incidentes, identificando e solucionando falhas e garantindo a estabilidade e disponibilidade dos serviços."
      ),
    },
  ],
};
