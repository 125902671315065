import Banner from "./banner.webp";
import BannerMobile from "./bannerMobile.webp";
import TeamWork from "./teamWork.webp";

const Images = {
  Banner,
  BannerMobile,
  TeamWork,
};

export default Images;
