import Images from "../../assets";
import Checklist from "./components/Checklist";
import Products from "./components/Products";
import styles from "./styles.module.scss";
function ForYourBusiness() {
  return (
    <section className={styles.background}>
      <svg
        width="111"
        height="29"
        viewBox="0 0 111 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.arrow}
      >
        <path
          d="M58.131 27.8086C56.62 29.1389 54.3556 29.1389 52.8445 27.8086L1.95314 -16.9977C-0.814465 -19.4344 0.908969 -23.9999 4.59638 -23.9999L106.379 -23.9999C110.067 -23.9999 111.79 -19.4344 109.022 -16.9977L58.131 27.8086Z"
          fill="white"
        />
      </svg>

      <div className={styles.container}>
        <h3 className={styles.title}>Soluções para o seu negócio</h3>
        <Checklist />
        <Products />
      </div>
      <div className={styles.image}>
        <img src={Images.Solutions} alt="" />
      </div>
    </section>
  );
}

export default ForYourBusiness;
