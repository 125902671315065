import * as Accordion from "@radix-ui/react-accordion";
import React, { useState } from "react";
import { items } from "./constants";
import styles from "./styles.module.scss";

function FAQ() {
  const [open, setOpen] = useState(false);
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>Perguntas frequentes</h3>
        <Accordion.Root type="single" defaultValue={null} collapsible>
          {items.map((item, i) => (
            <>
              <Accordion.Item
                className={styles.item}
                value={i + 1}
                key={i}
                open={i === open}
                onClick={() => setOpen(i === open ? null : i)}
              >
                <Accordion.Header asChild>
                  <Accordion.Trigger className={styles.trigger} asChild>
                    <button>
                      <p>{item.title}</p>
                      {open === i ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M6 12H12.5H18"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 4V20M20 12L4 12"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </button>
                  </Accordion.Trigger>
                </Accordion.Header>
                <Accordion.Content className={styles.content}>
                  {item.content}
                </Accordion.Content>
              </Accordion.Item>
              {i !== items.length - 1 && <hr className={styles.border} />}
            </>
          ))}
        </Accordion.Root>
      </div>
    </section>
  );
}

export default FAQ;
