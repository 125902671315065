import { default as AnjoApp } from "./anjo-app.webp";
import { default as CCGSite } from "./ccg-site.webp";
import { default as HygiaApp } from "./hygia-app.webp";

const Images = {
  HygiaApp,
  CCGSite,
  AnjoApp
};

export default Images;
