import Images from "../../assets";

export const items = [
  {
    title: "Hub de Inovação Feevale Techpark",
    images: [
      {
        src: Images.Feevale1,
        alt: "Fachada do Hub de Inovação Feevale Techpark",
      },
      {
        src: Images.Feevale2,
        alt: "Recepção do Hub de Inovação Feevale Techpark, contendo sofás, cadeiras e puffs, além de decoração",
      },
    ],
    address:
      "R. Cândido Silveira, 198 - Auxiliadora, Porto Alegre - RS, 90540-010",
  },
  {
    title: "Instituto Caldeira ",
    images: [
      {
        src: Images.Caldeira1,
        alt: "Escadaria e mesas dentro do Instituto Caldeira",
      },
      {
        src: Images.Caldeira2,
        alt: "Mesas para cooworking dentro do Instituto Caldeira",
      },
    ],
    address: "Tv. São José, 455 - Navegantes, Porto Alegre - RS, 90240-200",
  },
];
