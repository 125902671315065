import AboutUs from "./aboutUs.webp";
import Background from "./background.webp";
import BackgroundMobile from "./backgroundMobile.webp";

const Images = {
  Background,
  BackgroundMobile,
  AboutUs,
};

export default Images;
