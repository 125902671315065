import axios from "axios";
import { useState, useRef } from "react";
import { useIntl } from "react-intl";

function useContact() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    status: false,
    type: "",
    title: "",
    message: "",
  });
  const [captchaWarning, setCaptchaWarning] = useState(false);
  const intl = useIntl();
  const captchaRef = useRef(null);
  const [message, setMessage] = useState(
    intl.formatMessage({ id: "contact_button_send" })
  );

  function refreshAlert() {
    setAlert({
      status: false,
      type: "",
      title: "",
      message: "",
    });
  }

  async function sendData(event) {
    event.preventDefault();

    const captchaValue = captchaRef.current.getValue();
    if (!captchaValue) {
      setCaptchaWarning(true);
    } else {
      try {
        setLoading(true);
        const { name, email, phone, message } = event.target;
        if (!name.value || !email.value || !phone.value || !message.value)
          setMessage(intl.formatMessage({ id: "contact_fill_fields" }));
        await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
          user_id: "ajLx8-fKleO9MfqB5",
          service_id: "service_u0fehjr",
          template_id: "template_k47f34s",
          template_params: {
            candidate_name: name.value,
            candidate_email: email.value,
            candidate_phone: phone.value,
            candidate_message: message.value,
          },
          accessToken: "8V_LTArGHAyT3t3NCAlwH",
        });
      } catch (error) {
        setMessage(intl.formatMessage({ id: "contact_error" }));
        setAlert({
          status: true,
          type: "error",
          title: "Atenção!",
          message:
            "Revise os campos do formulário e certifique-se de que todos os campos foram preenchidos corretamente!",
        });
        setTimeout(() => {
          refreshAlert();
        }, 6000);
      } finally {
        setLoading(false);
        document.getElementById("contact-form")?.reset();
        window.grecaptcha.reset();
        setMessage(intl.formatMessage({ id: "contact_button_send" }));
        setAlert({
          status: true,
          type: "success",
          title: "Sucesso!",
          message: "Os dados foram enviados com sucesso!",
        });
        setTimeout(() => {
          refreshAlert();
        }, 6000);
      }
    }
  }
  return {
    loading,
    alert,
    captchaRef,
    captchaWarning,
    message,
    sendData,
    setCaptchaWarning,
  };
}

export default useContact;
