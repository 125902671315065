import React from "react";
import { useIntl } from "react-intl";
import FAQ from "../../../../components/FAQ";
import FormContact from "../../../../components/FormContact";
import { items } from "./constants";
import styles from "./styles.module.scss";
function ContactUs() {
  const intl = useIntl();

  return (
    <section className={styles.background} id="contact">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <FAQ items={items} />
        </div>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>
            {intl.formatMessage({
              id: "outsourcing_contactus_form_title",
            })}
          </h3>
          <FormContact />
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
