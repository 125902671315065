import React, { createContext, useEffect, useState } from "react";
import { LOCALES } from "../i18n/locales";

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [context, setContext] = useState("pt");
  const locale = LOCALES.PORTUGUESE;

  function handleData(data) {
    setContext(data);
    localStorage.clear();
    localStorage.setItem("@language", JSON.stringify(data));
  }

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("@language"));
    if (storage) {
      setContext(storage);
      localStorage.setItem("@language", JSON.stringify(storage));
    } else {
      setContext(locale);
      localStorage.setItem("@language", JSON.stringify(locale));
    }
  }, [locale]);

  return (
    <DataContext.Provider
      value={{
        context,
        setContext,
        handleData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
