import { default as Brazil } from "./brazil.png";
import { default as Spain } from "./spain.png";
import { default as USA } from "./usa.png";

const Flags = {
  Brazil,
  USA,
  Spain,
};

export default Flags;
