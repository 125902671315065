import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles.module.scss";

function Dropdown({ title, items }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.dropdown} onMouseLeave={() => setIsOpen(false)}>
      <button
        className={`${styles.dropdownTrigger} ${styles.link}`}
        onClick={() => setIsOpen((prev) => !prev)}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        {title}{" "}
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={isOpen ? styles.rotateArrow : ""}
        >
          <path
            d="M12.6666 6L7.99998 10.6667L3.33331 6"
            stroke="#37474F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      {isOpen && (
        <ul className={styles.dropdownContent} role="menu" id="dropdown-menu">
          {items.map((item, index) => (
            <li className={styles.link}>
              <Link key={index} to={item.link} role="menuitem">
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
