import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles.module.scss";

function Culture() {
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          <FormattedMessage
            id="about_culture_title"
            defaultMessage="A cultura Starta"
          />
        </h3>
        <p className={styles.description}>
          <FormattedMessage
            id="about_culture_description"
            defaultMessage="Acreditamos que criar uma empresa onde as pessoas gostem de trabalhar
          se baseia em relacionamentos, crescimento e impacto. Estamos a mais de
          10 anos atuando no mercado de tecnologia, buscando através do trabalho
          em equipe criar projetos que gerem valor para empresas e para a
          sociedade."
          />
        </p>
        <div className={styles.wrapper}>
          <div className={styles.item}>
            <h4 className={styles.itemTitle}>
              <FormattedMessage
                id="about_culture_mission_title"
                defaultMessage="Missão"
              />
            </h4>
            <p>
              <FormattedMessage
                id="about_culture_mission_text"
                defaultMessage="Facilitar a transformação digital de empresas, combinando design e
                  inovação para entregar soluções úteis e de extrema qualidade."
              />
            </p>
          </div>

          <div className={styles.item}>
            <h4 className={styles.itemTitle}>
              <FormattedMessage
                id="about_culture_vision_title"
                defaultMessage="Visão"
              />
            </h4>
            <p>
              <FormattedMessage
                id="about_culture_vision_text"
                defaultMessage="Ser referência nacional no desenvolvimento de soluções em
                  tecnologia, no mercado de seguros."
              />
            </p>
          </div>

          <div className={styles.item}>
            <h4 className={styles.itemTitle}>
              <FormattedMessage
                id="about_culture_values_title"
                defaultMessage="Valores"
              />
            </h4>
            <p>
              <FormattedMessage
                id="about_culture_values_text"
                defaultMessage=" Soluções: Design, inovação e qualidade Cultura: Responsabilidade,
                  comprometimento e agilidade Pessoas: Ética, colaboração, Incentivo
                  e valorização."
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Culture;
