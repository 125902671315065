import React from "react";
import { FormattedMessage } from "react-intl";
import { Images } from "../../assets";
import styles from "./styles.module.scss";

function Banner() {
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <img src={Images.Background} alt="" className={styles.image} />
        </div>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            <FormattedMessage
              id="products_banner_title"
              defaultMessage="Veja alguns dos produtos desenvolvidos pela Startaideia"
            />
          </h1>
          <h2 className={styles.description}>
            <FormattedMessage
              id="products_banner_description"
              defaultMessage="Conheça nossas soluções inteligentes desenvolvidas para serem altamente personalizáveis. Potencialize a oferta de serviços e produtos através de nossas plataformas digitais e garanta credibilidade que seu negócio merece."
            />
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Banner;
