import { useIntl } from "react-intl";
import Images from "../../assets";
import styles from "./styles.module.scss";

function About() {
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <svg
        width="111"
        height="53"
        viewBox="0 0 111 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.arrow}
      >
        <path
          d="M58.131 51.8086C56.62 53.1389 54.3556 53.1389 52.8445 51.8086L1.95314 7.00227C-0.814465 4.5656 0.908969 6.30427e-05 4.59638 6.27366e-05L106.379 5.42856e-05C110.067 5.39794e-05 111.79 4.56559 109.022 7.00227L58.131 51.8086Z"
          fill="#EAF0FA"
        />
      </svg>

      <div className={styles.wrapper}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: "healthProject_about_title",
          })}
        </h3>
        <p className={styles.description}>
          {intl.formatMessage({
            id: "healthProject_about_description",
          })}
        </p>
      </div>
      <div className={styles.image}>
        <img src={Images.About} alt="" />
      </div>
    </section>
  );
}

export default About;
