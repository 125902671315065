import React from "react";
import BusinessBenefits from "../../../components/BusinessBenefits";
import TestimonialsCustomers from "../../../components/TestimonialsCustomers";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Banner from "./components/Banner";
import Comparison from "./components/Comparison";
import FAQ from "./components/FAQ";
import WinWithUs from "./components/WinWithUs";

function Home() {
  return (
    <>
      <Navbar />
      <main>
        <Banner />
        <BusinessBenefits />
        <Comparison />
        <WinWithUs />
        <TestimonialsCustomers />
        <FAQ />
      </main>
      <Footer />
    </>
  );
}

export default Home;
