import React from "react";
import styles from "./styles.module.scss";
import Loader from '../../components/Loader';

function Button({
  width,
  text,
  appearance = "primary",
  children,
  iconPosition = "right",
  loading = false,
  ...rest
}) {
  return (
    <button
      {...rest}
      className={`
      ${styles.container} 
      ${styles[appearance]}
      `}
      style={{
        width: width && width,
      }}
    >
      {iconPosition === "left" ? children : ""}
      {loading && (
        <Loader size="20px" />
      )}
      <p>{text}</p>
      {iconPosition === "right" ? children : ""}
    </button>
  );
}

export default Button;
