import { useIntl } from "react-intl";
import BlueIcons from "../../../../../../assets/icons/blue";
import { checklist } from "./constants";
import styles from "./styles.module.scss";

function Checklist() {
  const intl = useIntl();
  return (
    <div className={styles.content}>
      <h4 className={styles.subtitle}>
        {intl.formatMessage({
          id: "healthProject_business_checklist_title",
        })}
      </h4>
      <p className={styles.subtitleText}>
        {intl.formatMessage({
          id: "healthProject_business_checklist_description",
        })}
      </p>
      <div className={styles.checklist}>
        {checklist.map((item, i) => (
          <div className={styles.checklistItem} key={i}>
            <BlueIcons.Check className={styles.icon} />
            <p className={styles.checklistItemText}>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Checklist;
