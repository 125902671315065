import Logos from "../../../assets/logos";
import styles from "../styles.module.scss";

function SocialMedia({ type = "desktop" }) {
  return (
    <div className={`${styles.socialMedia} ${styles[type]}`} role="navigation">
      <a
        href="https://www.instagram.com/startaideia/"
        referrerPolicy="no-referrer"
        className={styles.link}
        aria-label="Instagram"
      >
        <Logos.Instagram />
      </a>
      <a
        href="https://www.linkedin.com/company/startaideia/"
        referrerPolicy="no-referrer"
        className={styles.link}
        aria-label="LinkedIn"
      >
        <Logos.Linkedin />
      </a>
      <a
        href="https://www.facebook.com/startaideia/"
        referrerPolicy="no-referrer"
        className={styles.link}
        aria-label="Facebook"
      >
        <Logos.Facebook />
      </a>
    </div>
  );
}

export default SocialMedia;
