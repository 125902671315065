import axios from "axios";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Icons from "../../../../assets/icons";
import Slides from "../../../../components/Slides";
import styles from "./styles.module.scss";

function Testimonials() {
  const intl = useIntl();
  const [testimonials, setTestimonials] = useState([]);

  const getData = async () => {
    const response = await axios.get(
      "https://admin.carreiras.startaideia.dev/api/depoimentos-colaboradores"
    );

    setTestimonials(response.data.collaborator_testimonial);
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const description = {
    pt: "description_br",
    en: "description_en",
    es: "description_es",
  };

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          {intl.formatMessage({ id: "about_testimonials_title" })}
        </h3>
        <Slides items={testimonials}>
          {testimonials.map((testimonial) => (
            <div className={styles.card} key={testimonial.id}>
              <div className={styles.image}>
                <img
                  src={`https://startacarreiras.s3.us-east-1.amazonaws.com/${testimonial.image}`}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className={styles.wrapper}>
                <Icons.Quote className={styles.icon} />
                <p className={styles.description}>
                  {testimonial[description[intl.locale]]}
                </p>
                <p className={styles.name}>{testimonial.collaborator_name}</p>
                <p className={styles.role}>{testimonial.collaborator_role}</p>
              </div>
            </div>
          ))}
        </Slides>
      </div>
    </section>
  );
}

export default Testimonials;
