import styles from "./styles.module.scss";
import { FaRegCheckCircle, FaRegClock, FaRegTimesCircle } from "react-icons/fa";

function Alert({ type, title, text }) {
  return (
    <div className={`${styles.alertComponent} ${styles[type]} `}>
      <div className={styles.column}>
        <div className={styles.icon}>
        {type === "success" && <FaRegCheckCircle size={18} />}
        {type === "warning" && <FaRegClock size={18} />}
        {type === "error" && <FaRegTimesCircle size={18} />}
        </div>
      </div>
      <div className={styles.column}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Alert;
