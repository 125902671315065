import Carousel from "../../components/Carousel";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AboutUs from "./components/AboutUs";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import HowWeWork from "./components/HowWeWork";
import OurServices from "./components/OurServices";
import Stats from "./components/Stats";

function SoftwareFactory() {
  return (
    <>
      <Navbar />
      <main>
        <Banner />
        <AboutUs />
        <OurServices />
        <HowWeWork />
        <Stats />
        <Carousel />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default SoftwareFactory;
