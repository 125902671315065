import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import styles from "./styles.module.scss";

import PinkIcons from "../../../../assets/icons/pink";
import routesPaths from "../../../../routes/routesPaths";

function Solutions() {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        <FormattedMessage
          id="home_soluctions_title"
          defaultMessage="Soluções Starta para o seu negócio"
        />
      </h3>

      <div className={styles.content}>
        <div className={styles.item}>
          <PinkIcons.Bulb />
          <h4 className={styles.itemTitle}>
            <FormattedMessage
              id="home_soluctions_develpment_title"
              defaultMessage="Fábrica de Software"
            />
          </h4>
          <p>
            <FormattedMessage
              id="home_soluctions_develpment_text"
              defaultMessage="Desenvolvemos soluções de software exclusivas e eficientes que impulsionam o sucesso do seu negócio. Potencialize seus resultados com a tecnologia."
            />
          </p>
          <Button
            text="Saiba Mais"
            appearance="ghost"
            onClick={() => {
              navigate(routesPaths.softwareFactory);
            }}
          />
        </div>
        <div className={styles.item}>
          <PinkIcons.Internet />

          <h4 className={styles.itemTitle}>
            <FormattedMessage
              id="home_soluctions_web_title"
              defaultMessage="Outsourcing"
            />
          </h4>
          <p>
            <FormattedMessage
              id="home_soluctions_web_text"
              defaultMessage="Contrate profissionais especializados em tecnologia. Conte com a nossa expertise em recrutamento e seleção e gerenciamento destes profissionais."
            />
          </p>
          <Button
            text="Saiba Mais"
            appearance="ghost"
            onClick={() => {
              navigate(routesPaths.outsourcing);
            }}
          />
        </div>
        <div className={styles.item}>
          <PinkIcons.Browser />

          <h4 className={styles.itemTitle}>
            <FormattedMessage
              id="home_soluctions_conception_title"
              defaultMessage="Produtos Starta"
            />
          </h4>
          <p>
            <FormattedMessage
              id="home_soluctions_conception_text"
              defaultMessage="Saiba como nossos produtos podem ser a chave para levar a sua empresa a novos patamares de sucesso. Soluções personalizáveis que já contam com milhares de clientes."
            />
          </p>
          <Button
            text="Saiba Mais"
            appearance="ghost"
            onClick={() => {
              navigate(routesPaths.products);
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Solutions;
