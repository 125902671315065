import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Icons from "../../../../assets/icons";
import { items } from "./constants";
import styles from "./styles.module.scss";

function Clients() {
  const [disabledArrow, setDisabledArrow] = useState("prev");
  const [count, setCount] = useState(1);

  const carouselRef = useRef(null);
  const itemWidth = 270;

  const handleNext = () => {
    carouselRef.current.scrollLeft += itemWidth;

    const itemsDisplayed = Math.floor(
      carouselRef.current.offsetWidth / itemWidth
    );

    const isLastItem = items.length - count === itemsDisplayed;
    setDisabledArrow(isLastItem ? "next" : null);
    setCount((prev) => prev + 1);
  };

  const handlePrev = () => {
    carouselRef.current.scrollLeft -= itemWidth;
    setCount((prev) => prev - 1);
    setDisabledArrow(count === 1 ? "prev" : null);
  };

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.title}>
            <FormattedMessage
              id="home_clients_title"
              defaultMessage="Clientes"
            />
          </h3>
          <div className={styles.arrows}>
            <button
              className={styles.arrow}
              onClick={handlePrev}
              aria-label="Previous"
              disabled={disabledArrow === "prev"}
            >
              <Icons.ArrowRightCircle />
            </button>
            <button
              className={styles.arrow}
              onClick={handleNext}
              aria-label="Next"
              disabled={disabledArrow === "next"}
            >
              <Icons.ArrowRightCircle />
            </button>
          </div>
        </div>

        <div className={styles.carousel} ref={carouselRef}>
          {items.map((item) => (
            <div className={styles.image} key={item.name}>
              <img
                src={item.image}
                alt={item.name}
                draggable={false}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Clients;
