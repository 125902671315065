import React from "react";
import { Link } from "react-router-dom";
import Logos from "../../../../assets/logos";
import routesPaths from "../../../../routes/routesPaths";
import styles from "./styles.module.scss";

function Navbar() {
  return (
    <header className={styles.background}>
      <div className={styles.container}>
        <Link to={routesPaths.home}>
          <Logos.Startaideia className={styles.logo} aria-label="Home" />
        </Link>
      </div>
    </header>
  );
}

export default Navbar;
