import Button from "../../../../components/Button";
import styles from "./styles.module.scss";

function Finish({ setStep, setModalData, setModalOpen }) {
  return (
    <div className={styles.content}>
      <h4 className={styles.title}>
        Parabéns sua candidatura foi registrada com sucesso!
      </h4>
      <p className={styles.text}>
        Obrigado por se candidatar. Aguarde novidades sobre o processo seletivo.
      </p>
      <Button
        onClick={() => {
          setStep(1);
          setModalData?.({ isOpen: false });
          setModalOpen?.(false);
        }}
      >
        Ok, obrigado
      </Button>
    </div>
  );
}

export default Finish;
