import Anjo from "./anjo.webp";
import Background from "./background.webp";
import BackgroundMobile from "./backgroundMobile.webp";
import BannerLogo from "./bannerLogo.webp";
import CCG from "./ccg.webp";
import Hygia from "./hygia.webp";
import Sicredi from "./sicredi.webp";
import Tecredi from "./tecredi.webp";
import Tokyo from "./tokyo.webp";

const Images = {
  Background,
  BannerLogo,
  BackgroundMobile,
  Anjo,
  CCG,
  Hygia,
  Sicredi,
  Tecredi,
  Tokyo,
};

export default Images;
