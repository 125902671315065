import styles from "../styles.module.scss";

function File({ file, setFile }) {
  const getBase64 = (file) => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.file}>
      <input
        type="file"
        id="file"
        onChange={(e) => getBase64(e.target.files[0])}
        required
        multiple={false}
      />
      {file === "" ? (
        <label htmlFor="file">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 16L4 17C4 18.6569 5.34315 20 7 20L17 20C18.6569 20 20 18.6569 20 17L20 16M16 8L12 4M12 4L8 8M12 4L12 16"
              stroke="#78909C"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Envie seu Currículo
        </label>
      ) : (
        <label htmlFor="file" className={styles.file}>
          Currículo anexado com sucesso!
        </label>
      )}
    </div>
  );
}

export default File;
