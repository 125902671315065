import routesPaths from "../../../../routes/routesPaths";
import { formatMessage } from "../../../../utils/formatMessage";
import { Images } from "../../assets";

export const products = [
  {
    title: "Gestão de Visitas",
    text: "A solução definitiva para otimizar suas operações comerciais é o Gestão de visitas! Nosso aplicativo funciona tanto online quanto offline, proporcionando total liberdade aos Gerentes de Negócios. Com formulários personalizados na palma da mão, agora é mais fácil do que nunca capturar informações importantes durante suas visitas. Tenha acesso instantâneo ao histórico de relatórios preenchidos, permitindo uma análise rápida e eficaz das atividades e com a funcionalidade de localização integrada, planeje rotas eficientes para cada visita, economizando tempo e recursos preciosos. Eleve sua cooperativa a um novo nível!",
    image: Images.Araxingu,
    link: "https://gestaodevisitas.startaideia.com.br/"
  },
  {
    title: formatMessage("products_health_title"),
    text: formatMessage("products_health_description"),
    image: Images.HealthProject,
    link: routesPaths.healthProject,
  },
  {
    title: "Open Plataforma",
    text: formatMessage("products_open_description"),
    image: Images.Open,
    link: "https://openplataforma.com.br/",
  },
  {
    title: "InsurON",
    image: Images.InsurOn,
    text: formatMessage("products_insuron_description"),
  },
  // {
  //   title: "Comunidade ExoHub",
  //   image: Images.Araxingu,
  //   text: formatMessage("products_exohub_description"),
  //   link: "https://comunidade.exohub.com.br/",
  // },
];
