import BlueIcons from "../../../../../../assets/icons/blue";
import { formatMessage } from "../../../../../../utils/formatMessage";

export const products = [
  {
    icon: <BlueIcons.Stethoscope />,
    text: formatMessage("healthProject_business_products_channel"),
  },
  {
    icon: <BlueIcons.Puzzle />,
    text: formatMessage("healthProject_business_products_platform"),
  },
  {
    icon: <BlueIcons.Users />,
    text: formatMessage("healthProject_business_products_clients"),
  },
  {
    icon: <BlueIcons.BarChart />,
    text: formatMessage("healthProject_business_products_efficiency"),
  },
  {
    icon: <BlueIcons.Filter />,
    text: formatMessage("healthProject_business_products_conversion"),
  },
  {
    icon: <BlueIcons.Trophy />,
    text: formatMessage("healthProject_business_products_competitive"),
  },
  {
    icon: <BlueIcons.Notebook />,
    text: formatMessage("healthProject_business_products_communication"),
  },
];
