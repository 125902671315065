import React from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Player from "../../components/Player";
import Testimonials from "./components/Testimonials";

import Banner from "./components/Banner";
import Culture from "./components/Culture";
import Hub from "./components/Hub";

function About() {
  return (
    <>
      <Navbar />
      <main>
        <Banner />
        <Culture />
        <Player />
        <Hub />
        <Testimonials />
      </main>
      <Footer />
    </>
  );
}

export default About;
