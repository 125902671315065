import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import Button from "../../../../components/Button";
import routesPaths from "../../../../routes/routesPaths";
import styles from "./styles.module.scss";

function JobInfo({ modalData, setModalData, setStep }) {
  const { description, assignment, requirement, differentials } = modalData;
  return (
    <>
      <div className={styles.header}>
        <Dialog.Title className={styles.title} as="h4">
          Informações da vaga
        </Dialog.Title>
        <div className={styles.icons}>
          <Link to={`${routesPaths.careers}/${modalData.slug}`}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 6H6C4.89543 6 4 6.89543 4 8V18C4 19.1046 4.89543 20 6 20H16C17.1046 20 18 19.1046 18 18V14M14 4H20M20 4V10M20 4L10 14"
                stroke="#37474F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>

          <button onClick={() => setModalData({ isOpen: false })}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 18L18 6M6 6L18 18"
                stroke="#37474F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <hr />
      <div className={styles.content}>
        <section className={styles.section}>
          <h5 className={styles.subtitle}>Sobre a vaga</h5>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.items}
          />
        </section>
        <section className={styles.section}>
          <h5 className={styles.subtitle}>Responsabilidades</h5>
          <div
            dangerouslySetInnerHTML={{ __html: assignment }}
            className={styles.items}
          />
        </section>
        <section className={styles.section}>
          <h5 className={styles.subtitle}>Requisitos</h5>
          <div
            dangerouslySetInnerHTML={{ __html: requirement }}
            className={styles.items}
          />
        </section>
        {differentials && (
          <section className={styles.section}>
            <h5 className={styles.subtitle}>Diferenciais</h5>
            <div
              dangerouslySetInnerHTML={{ __html: differentials }}
              className={styles.items}
            />
          </section>
        )}
        <Button onClick={() => setStep(2)}>Aplique agora</Button>
      </div>
    </>
  );
}

export default JobInfo;
