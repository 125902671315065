import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as Browser } from "./browser.svg";
import { ReactComponent as Chat } from "./chat.svg";
import { ReactComponent as CheckRounded } from "./check-rounded.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as Coffee } from "./coffee.svg";
import { ReactComponent as DocumentPerformance } from "./document-performance.svg";
import { ReactComponent as Graphic } from "./graphic.svg";
import { ReactComponent as Hand } from "./hand.svg";
import { ReactComponent as HotAirBalloon } from "./hot-air-balloon.svg";
import { ReactComponent as Internet } from "./internet.svg";
import { ReactComponent as Bulb } from "./light-bulb.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Magnet } from "./magnet.svg";
import { ReactComponent as Trophy } from "./trophy.svg";
import { ReactComponent as UserTalk } from "./user-talk.svg";

const PinkIcons = {
  Bulb,
  Internet,
  Browser,
  Chat,
  Trophy,
  HotAirBalloon,
  DocumentPerformance,
  Coffee,
  UserTalk,
  Location,
  Clock,
  ArrowRight,
  Hand,
  Graphic,
  Magnet,
  CheckRounded,
};

export default PinkIcons;
