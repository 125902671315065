import { useIntl } from "react-intl";
import Button from "../../../../components/Button";
import useIsMobile from "../../../../hooks/useIsMobile";
import Images from "../../assets";
import styles from "./styles.module.scss";

function Banner() {
  const intl = useIntl();
  const { isMobile } = useIsMobile();
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1>
            {intl.formatMessage({
              id: "softwarefactory_banner_title",
            })}
          </h1>
          <h2>
            {intl.formatMessage({
              id: "softwarefactory_banner_subtitle",
            })}
          </h2>
        </div>
        <a href="#contact">
          <Button
            text={intl.formatMessage({ id: "softwarefactory_banner_button" })}
          />
        </a>
      </div>
      <div className={styles.image}>
        <img
          src={isMobile ? Images.BackgroundMobile : Images.Background}
          alt=""
        />
      </div>
    </section>
  );
}

export default Banner;
