import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Icons from "../../../../assets/icons";
import Slides from "../../../../components/Slides";
import { DataContext } from "../../../../context/DataContext";
import styles from "./styles.module.scss";

function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);

  const { context } = useContext(DataContext);
  const intl = useIntl();

  const getData = async () => {
    const response = await axios.get(
      "https://admin.carreiras.startaideia.dev/api/depoimentos-clientes"
    );

    setTestimonials(response.data.client_testimonial);
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const description = {
    pt: "description_br",
    en: "description_en",
    es: "description_es",
  };

  return (
    <section className={styles.background}>
      <div className={styles.wrapper}>
        <h3 className={styles.title}>
          {intl.formatMessage({
            id: "home_testimonials_title",
            defaultMessage: "Depoimento de nossos clientes",
          })}
        </h3>
        <Slides items={testimonials}>
          {testimonials.map((testimonial) => (
            <div className={styles.card} key={testimonial.id}>
              <div className={styles.info}>
                <p className={styles.quote}>
                  <Icons.Quote className={styles.quoteInit} />
                  {testimonial[description[context]]}
                  <Icons.Quote className={styles.quoteEnd} />
                </p>
                <p className={styles.name}>{testimonial.client_name}</p>
                <p className={styles.company}>{testimonial.company_name}</p>
              </div>
              <div className={styles.image}>
                <img
                  src={`https://startacarreiras.s3.us-east-1.amazonaws.com/${testimonial.image}`}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          ))}
        </Slides>
      </div>
    </section>
  );
}

export default Testimonials;
