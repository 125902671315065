import React from "react";
import { useIntl } from "react-intl";
import PinkIcons from "../../../../assets/icons/pink";
import FramedImage from "../../../../components/FramedImage";
import Images from "../../assets";
import styles from "./styles.module.scss";

function OurMethodology() {
  const intl = useIntl();
  return (
    <section className={styles.container}>
      <h3 className={styles.title}>
        {intl.formatMessage({
          id: "outsourcing_ourmethodology_title",
        })}
      </h3>
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <h4 className={styles.contentTitle}>
            {intl.formatMessage({
              id: "outsourcing_ourmethodology_subtitle",
            })}
          </h4>
          <p className={styles.contentText}>
            {intl.formatMessage({
              id: "outsourcing_ourmethodology_description",
            })}
          </p>
        </div>
        <div className={styles.image}>
          <FramedImage src={Images.Hands} />
        </div>
      </div>
      <div className={styles.items}>
        <div className={styles.item}>
          <PinkIcons.CheckRounded />
          <p>
            {intl.formatMessage({
              id: "outsourcing_ourmethodology_culture_description",
            })}
          </p>
        </div>
        <div className={styles.item}>
          <PinkIcons.CheckRounded />
          <p>
            {intl.formatMessage({
              id: "outsourcing_ourmethodology_followup_description",
            })}
          </p>
        </div>
        <div className={styles.item}>
          <PinkIcons.CheckRounded />
          <p>
            {intl.formatMessage({
              id: "outsourcing_ourmethodology_management_description",
            })}
          </p>
        </div>
      </div>
    </section>
  );
}

export default OurMethodology;
