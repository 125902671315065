import React from "react";
import video from "../../assets/videos/startaCelebration.mp4";
import styles from "./styles.module.scss";

function Player() {
  return (
    <div className={styles.container}>
      <video
        src={video}
        title="Startaideia - Vídeo Confraternização 2022"
        allowFullScreen
        loading="lazy"
        controls
        controlsList="nodownload"
      />
    </div>
  );
}

export default Player;
