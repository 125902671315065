function PT() {
  return (
    <div>
      <h2>PLANO DE RESPOSTA A INCIDENTES DE SEGURANÇA</h2>
      <h3>
        Leia a seguir o nosso framework com planos e ações relacionados a
        incidentes de segurança e privacidade de dados.
      </h3>

      <section>
        <h4>O que é um incidente de segurança?</h4>
        <p>
          A Stataideia define um incidente de segurança em seus serviços online
          como uma violação confirmada de segurança que leva à destruição
          acidental ou ilegal, perda, alteração, divulgação não autorizada ou
          acesso a dados de clientes ou dados pessoais durante o processamento
          pela Startaideia.
        </p>
        <p>
          Por exemplo, acesso não autorizado à infraestrutura de serviços online
          da Strataideia e exfiltração de dados do cliente constituirião um
          incidente de segurança, enquanto eventos de conformidade que não
          afetam a confidencialidade, integridade ou disponibilidade de serviços
          ou dados do cliente não são considerados incidentes de segurança.
        </p>
      </section>

      <section>
        <h4>Como a Startaideia responde a incidentes de segurança?</h4>
        <p>
          Sempre que há um incidente de segurança, a Startaideia se esforça para
          responder de forma rápida e eficaz para proteger os serviços
          Startaideia e os dados do cliente. A Startaideia emprega uma
          estratégia de resposta a incidentes projetada para investigar, conter
          e remover ameaças de segurança com rapidez e eficiência.
        </p>
        <p>
          Os serviços de nuvem da Startaideia são continuamente monitorados
          quanto a sinais de comprometimento. Além do monitoramento e alerta de
          segurança automatizados, todos os funcionários recebem treinamento
          anual para reconhecer e relatar sinais de possíveis incidentes de
          segurança. Qualquer atividade suspeita detectada por funcionários,
          clientes ou ferramentas de monitoramento de segurança é encaminhada
          para as equipes de Resposta de Segurança específicas do Serviço para
          investigação. Todas as equipes de operações de serviço, incluindo as
          equipes de resposta de segurança específicas do serviço, mantêm uma
          rotação profunda de plantão para garantir que os recursos estejam
          disponíveis para resposta a incidentes 24 horas por dia, 7 dias por
          semana, 365 dias por ano. Nossas rotações de plantão permitem que a
          Startaideia monte uma resposta eficaz a incidentes em qualquer momento
          ou escala, incluindo eventos generalizados ou simultâneos.
        </p>
        <p>
          Quando uma atividade suspeita é detectada e escalada, as equipes de
          Resposta de Segurança específicas do Serviço iniciam um processo de
          análise, contenção, erradicação e recuperação. Essas equipes coordenam
          a análise do possível incidente para determinar seu escopo, incluindo
          qualquer impacto nos clientes ou nos dados do cliente. Com base nessa
          análise, as equipes de resposta de segurança específicas do serviço
          trabalham com as equipes de serviço afetadas para desenvolver um plano
          para conter a ameaça e minimizar o impacto do incidente, erradicar a
          ameaça do ambiente e recuperar totalmente para um estado seguro
          conhecido. As equipes de serviço relevantes implementam o plano com
          suporte das equipes de resposta de segurança específicas do serviço
          para garantir que a ameaça seja eliminada com sucesso e os serviços
          afetados passem por uma recuperação completa.
        </p>
        <p>
          Depois que um incidente é resolvido, as equipes de serviço implementam
          as lições aprendidas com o incidente para melhor prevenir, detectar e
          responder a incidentes semelhantes no futuro. Incidentes de segurança
          selecionados, especialmente aqueles que afetam o cliente ou resultam
          em uma violação de dados, passam por um incidente post-mortem
          completo. O post-mortem é projetado para identificar lapsos técnicos,
          falhas de procedimento, erros manuais e outras falhas de processo que
          possam ter contribuído para o incidente ou que foram identificadas
          durante o processo de resposta ao incidente. As melhorias
          identificadas durante o post-mortem são implementadas com a
          coordenação das equipes de resposta de segurança específicas do
          serviço para ajudar a prevenir futuros incidentes e melhorar os
          recursos de detecção e resposta.
        </p>
      </section>
      <section>
        <h4>Contatos</h4>
        <p>
          Para notificar qualquer incidente de segurança e privacidade de dados,
          por favor, utilize nosso{" "}
          <a href="https://startaideia.com.br/suporte">Suporte</a>.
        </p>
      </section>
    </div>
  );
}

export default PT;
