import { useIntl } from "react-intl";
import FormContact from "../FormContact";
import styles from "./styles.module.scss";

function Contact({
  title = "contact_title",
  description = "contact_description",
}) {
  const intl = useIntl();

  return (
    <section className={styles.background} id="contact">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>{intl.formatMessage({ id: title })}</h3>
          <p className={styles.description}>
            {intl.formatMessage({ id: description })}
          </p>
        </div>
        <FormContact />
      </div>
    </section>
  );
}

export default Contact;
