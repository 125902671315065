import { PopupButton } from "react-calendly";
import { useIntl } from "react-intl";
import Loader from "../../../../components/Loader";
import useContact from "../../../../hooks/useContact";
import styles from "./styles.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../../components/Button";
import Alert from "../../../../components/Alert";

function Contact({ isMobile }) {
  const {
    loading,
    alert,
    captchaRef,
    captchaWarning,
    message,
    sendData,
    setCaptchaWarning,
  } = useContact();
  const intl = useIntl();
  return (
    <section className={styles.background} id="contact">
    {alert.status && (
      <Alert type={alert.type} title={alert.title} text={alert.message} />
    )}
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>
            {intl.formatMessage({
              id: "healthProject_contact_title",
            })}
          </h3>
          <p className={styles.description}>
            {intl.formatMessage({
              id: "healthProject_contact_description",
            })}
          </p>
        </div>
        {isMobile && (
          <PopupButton
            url="https://calendly.com/startaideia/demonstracao-portal-de-saude"
            rootElement={document.getElementById("root")}
            text={intl.formatMessage({
              id: "healthProject_contact_popup",
            })}
            className={styles.button}
          />
        )}
        <form id="contact-form" onSubmit={sendData} className={styles.form}>
          <fieldset>
            <legend className={styles.formTitle}>
              {intl.formatMessage({
                id: "healthProject_contact_form_title",
              })}
            </legend>
            <input
              type="text"
              placeholder={intl.formatMessage({
                id: "healthProject_contact_form_name",
              })}
              aria-labelledby="placeholder"
              className={styles.input}
              name="name"
              required
            />
            <input
              type="email"
              placeholder={intl.formatMessage({
                id: "healthProject_contact_form_email",
              })}
              aria-labelledby="placeholder"
              className={styles.input}
              name="email"
              required
            />
            <input
              type="text"
              placeholder={intl.formatMessage({
                id: "healthProject_contact_form_phone",
              })}
              aria-labelledby="placeholder"
              name="phone"
              mask="999.999.999-99"
              maxLength={15}
              className={styles.input}
              inputMode="numeric"
            />
            <textarea
              placeholder={intl.formatMessage({
                id: "healthProject_contact_form_message",
              })}
              aria-labelledby="placeholder"
              className={styles.input}
              name="message"
            />
            <ReCAPTCHA
              ref={captchaRef}
              sitekey="6LdKkQMqAAAAAKTql9WdR4ACMxcyphI9kJqOw47S"
              onChange={() => setCaptchaWarning(false)}
            />
            {captchaWarning && (
              <i className={styles.error}>
                Atenção! É obrigatório o preenchimento do Captcha!
              </i>
            )}
          </fieldset>

          {loading ? (
            <Loader />
          ) : (
            <Button text={message} type="submit" className={styles.button} />
          )}
        </form>
      </div>
    </section>
  );
}

export default Contact;
