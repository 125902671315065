import { useIntl } from "react-intl";
import Images from "../../assets";
import styles from "./styles.module.scss";

function Banner({ isMobile }) {
  const intl = useIntl();
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            {intl.formatMessage({
              id: "healthProject_banner_title",
            })}
          </h1>
          <h2 className={styles.subtitle}>
            {intl.formatMessage({
              id: "healthProject_banner_subtitle",
            })}
          </h2>
          <div className={`${styles.image} ${styles.mobile}`}>
            <img src={Images.BannerMobile} alt="" />
          </div>
          <a
            className={styles.button}
            href={isMobile ? "#contact" : "#demonstration"}
          >
            {intl.formatMessage({
              id: "healthProject_banner_button",
            })}
          </a>
        </div>
      </div>
      <div className={`${styles.image} ${styles.desktop}`}>
        <img src={Images.BannerDesktop} alt="" />
      </div>
    </section>
  );
}

export default Banner;
